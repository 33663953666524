import { EDITORS_PICK_STORY_QUEUE_TYPE } from "@src/api/v1/storyQueue";
import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import Column from "@src/app/components/Layouts/Column/Column";
import HighlightContainer from "@src/app/components/Layouts/HighlightContainer/HighlightContainer";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import StandardCardLayout from "@src/app/components/Layouts/StandardCardLayout/StandardCardLayout";
import EditorsPick from "@src/app/components/Modules/LandingPage/EditorsPick/EditorsPick";
import useStoryQueue from "@src/app/hooks/useStoryQueue";
import formatPublishedDate from "src/app/helper/formatPublishedDate";
import { EditorPickArticle } from "src/app/hooks/useHomePageListing";
import { ArticleCard } from "src/app/types/Article";

import styles from "./EditorsPick.module.css";

type ArticleCardBlock = {
  articles: ArticleCard[];
};

type EditorPickArticleCards = {
  editorsPickArticle: EditorPickArticle;
} & ArticleCardBlock;

export default function EditorPickArticles({
  articles,
  editorsPickArticle,
}: EditorPickArticleCards) {
  const { displayTitle, articleCard: editorsPickArticleCard } =
    editorsPickArticle;
  const { data: storyQueuePayload } = useStoryQueue({
    type: EDITORS_PICK_STORY_QUEUE_TYPE,
  });
  const storyQueueArticles = storyQueuePayload?.data.articles[0]
    ? storyQueuePayload.data.articles[0]
    : undefined;
  const displayedEditorsPickArticleCard: ArticleCard | undefined =
    storyQueueArticles || editorsPickArticleCard;
  const displayedEditorPickTitle =
    storyQueuePayload?.data.displayTitle || displayTitle;

  const editorsPickData = {
    articleTitle: displayedEditorsPickArticleCard?.articleName || "",
    articleUrl: displayedEditorsPickArticleCard?.articlePath || "",
    authorImgUrl:
      (displayedEditorsPickArticleCard?.authors.length &&
        displayedEditorsPickArticleCard.authors[0].headshotImageUrl) ||
      "",
    authorName:
      (displayedEditorsPickArticleCard?.authors.length &&
        displayedEditorsPickArticleCard.authors[0]?.name) ||
      "",
    authorUrl:
      (displayedEditorsPickArticleCard?.authors.length &&
        displayedEditorsPickArticleCard.authors[0].url) ||
      "",
    categoryLabelProps: {
      href: displayedEditorsPickArticleCard?.category
        ? displayedEditorsPickArticleCard.category.path
        : "",
      label: displayedEditorsPickArticleCard?.category
        ? displayedEditorsPickArticleCard.category.name
        : "",
    },
    editorsPickTitle: displayedEditorPickTitle || "",
    imgUrl: displayedEditorsPickArticleCard?.imgUrl || "",
    imgWidth: displayedEditorsPickArticleCard?.imgWidth || 570,
    imgHeight: displayedEditorsPickArticleCard?.imgHeight || 380,
    paragraphContent: displayedEditorsPickArticleCard?.blurb || "",
    testId: "editors-pick-content",
  };

  return (
    <>
      {displayedEditorsPickArticleCard && (
        <div className={styles.editorsPick}>
          <HighlightContainer>
            <MainContainer>
              <EditorsPick {...editorsPickData} />
            </MainContainer>
          </HighlightContainer>
        </div>
      )}

      <div className={styles.subArtcles}>
        <MainContainer>
          <Row>
            <Column>
              <StandardCardLayout>
                {articles.map((article: ArticleCard) => {
                  const {
                    articleName,
                    id,
                    blurb,
                    category,
                    authors,
                    imgUrl,
                    imgWidth,
                    imgHeight,
                    publishedDate,
                  } = article;
                  const articlePath = `${article.articlePath}?ref=home-latest-articles`;
                  return (
                    <ArticleCardContent
                      key={id}
                      articlePath={articlePath}
                      articleTitle={articleName}
                      authors={authors}
                      categoryLabelProps={{
                        href: category.path,
                        label: category.name,
                      }}
                      date={formatPublishedDate(publishedDate)}
                      hasAuthorAndDate
                      hasSidePadding
                      hasBackground
                      hasSnippet
                      imgUrl={imgUrl}
                      imgWidth={imgWidth}
                      imgHeight={imgHeight}
                      paragraphContent={blurb}
                      testId="article-card-content-popular"
                    />
                  );
                })}
              </StandardCardLayout>
            </Column>
          </Row>
        </MainContainer>
      </div>
    </>
  );
}
