import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import { getStoryBookPaginationURL } from "@src/app/helper/storybook";
import cx from "classnames";
import { type ReactElement } from "react";

import styles from "./Pagination.module.css";

type Props = Readonly<{
  isInternalLink?: boolean;
  displayText: string;
  pageLink?: string;
  isActive?: boolean;
  hasNoBorder?: boolean;
  isStoryBook?: boolean;
  testId?: string;
}>;

export default function PaginationButton({
  displayText,
  pageLink,
  isActive,
  hasNoBorder,
  isStoryBook,
  isInternalLink,
  testId,
}: Props): ReactElement {
  return (
    <li
      data-testid={testId}
      className={cx(
        styles.pagerItem,
        isActive && styles.active,
        hasNoBorder && styles.ellipsis,
      )}
    >
      {isInternalLink ? (
        <InternalLink
          className={cx(hasNoBorder && styles.ellipsis)}
          to={pageLink || "/"}
        >
          <span aria-hidden="true">{displayText}</span>
        </InternalLink>
      ) : (
        <a
          className={cx(hasNoBorder && styles.ellipsis)}
          aria-label={`Go to page ${displayText}`}
          href={
            isStoryBook && pageLink
              ? getStoryBookPaginationURL(pageLink)
              : pageLink
          }
        >
          <span aria-hidden="true">{displayText}</span>
        </a>
      )}
    </li>
  );
}
