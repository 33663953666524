import { OG_SITE_NAME } from "@src/app/components/Blocks/MetaTags/Constants";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import Avatar from "@src/app/components/Elements/Avatar/Avatar";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import { removeLeadingSlash } from "@src/app/utils/helpers";
import type { ReactElement } from "react";

import styles from "./ContributorProfile.module.css";

type Props = Readonly<{
  name: string;
  imageUrl: string;
  designation: string;
  bio: string;
  pathname: string;
  testId?: string;
}>;

export default function ContributorProfile({
  name,
  imageUrl,
  designation,
  bio,
  pathname,
  testId = "contributor-profile",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.contributorProfile}>
      <MetaTags
        title={`${name}'s Column | ${OG_SITE_NAME}`}
        description={bio}
        ogType=""
        articlePageclass="page"
        slug={removeLeadingSlash(pathname)}
      />
      <MainContainer>
        <Row isVCenter={true}>
          <Column className={styles.right}>
            <div className={styles.info}>
              <div data-testid={`${testId}-image`} className={styles.image}>
                <Avatar src={imageUrl} alt={name} />
              </div>
              <h1 data-testid={`${testId}-name`} className={styles.name}>
                {name}
              </h1>
              <p
                data-testid={`${testId}-designation`}
                className={styles.designation}
              >
                {designation}
              </p>
            </div>
          </Column>
          <Column className={styles.left}>
            <p data-testid={`${testId}-bio`} className={styles.bio}>
              {bio}
            </p>
          </Column>
        </Row>
      </MainContainer>
    </div>
  );
}
