import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./StandardCardLayout.module.css";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode;
}>;

export default function StandardCardLayout({
  children,
  testId = "standard-card-layout",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.standardCardLayoutWrapper)}>
      {children}
    </div>
  );
}
