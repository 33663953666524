import Icon from "@src/app/assets/images/newsletter-culture-icon.svg";
import SignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./NewsletterWidget.module.css";

type Props = Readonly<{
  headerTitle: string;
  bodyText: string;
  isDark?: boolean;
  isThinkCulture?: boolean;
  testId?: string;
}>;

export default function NewsletterWidget({
  isDark,
  isThinkCulture,
  testId,
  headerTitle,
  bodyText,
}: Props): ReactElement {
  return (
    <div
      data-testid={testId}
      className={cx(styles.newsletterWidgetContainer, isDark && styles.dark)}
    >
      <div className={styles.headerContainer}>
        {!isDark && (
          <img
            data-testid={`${testId}-thinkCultureIcon`}
            src={Icon}
            alt="ThinkCulture Icon"
            width="60"
          />
        )}
        <h3
          data-testid={`${testId}-headerTitle`}
          className={cx(isDark && styles.dark)}
        >
          {headerTitle}
        </h3>
      </div>
      <p data-testid={`${testId}-bodyText`}>{bodyText}</p>
      <SignupForm isDark={isDark} isThinkCulture={isThinkCulture} />
    </div>
  );
}
