import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getWindowHref } from "@src/app/utils/helpers";
import {
  NewsLetterCode,
  NewsletterPostRequestBody,
  NewsletterPostResponse,
} from "@typings/Newsletter";
import cx from "classnames";
import type { ReactElement } from "react";
import { FormEvent, useState } from "react";
import { useLocation } from "react-router-dom";

import getApiFunctionUrl from "../../../helper/getApiFunctionUrl";
import styles from "./NewsletterSignupForm.module.css";

type Props = Readonly<{
  isThinkCulture?: boolean;
  isDark?: boolean;
  isLarger?: boolean;
  testId?: string;
}>;

export default function SignupForm({
  isThinkCulture,
  isDark,
  isLarger = false,
  testId = "newsletter-form",
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const location = useLocation();

  async function handleOnSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    // Validate general email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Regular expression to check for invalid email patterns
    // This includes conditions such as starting with a dot, ending with a dot,
    // multiple consecutive dots, empty local part, trailing dot in the local part, etc.
    const invalidEmailPattern = /^\.|\.{2,}|@\.|\..*@$|^[^@]+@\.$/;

    // validate email
    if (
      email &&
      emailRegex.test(email) && // Valid email format
      !invalidEmailPattern.test(email) && // No invalid patterns
      !email.split("@")[0].endsWith(".") // Local part doesn't end with a dot
    ) {
      sendDataToGTM({
        event: "tcCustomEvent",
        eventCategory: "newsletter",
        eventLabel: getWindowHref(),
        eventAction: "submit",
      });

      const utmSourceString: string =
        new URLSearchParams(location.search).get("utm_source") || "";
      const newsletterCode: NewsLetterCode = isThinkCulture ? 2 : 1;
      const newsletterPostRequestBody: NewsletterPostRequestBody = {
        email,
        newsletterCode,
        utmSource: utmSourceString,
        path: location.pathname,
      };
      try {
        const res = await fetch(getApiFunctionUrl("newsletter"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newsletterPostRequestBody),
        });
        const data = (await res.json()) as NewsletterPostResponse;
        if (data.msg) {
          setErrorMsg(""); // unset error message
          setSuccessMsg(data.msg);
          return;
        }

        if (data.errorMsg) {
          setErrorMsg(data.errorMsg);
          return;
        }
      } catch (e: unknown) {
        setErrorMsg("Newsletter sign up failed");
        return;
      }
    } else {
      setErrorMsg("Please enter a valid Email ID!");
      return;
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <form
      onSubmit={(e: FormEvent<HTMLFormElement>) => void handleOnSubmit(e)}
      data-testid={testId}
      className={cx(styles.signupFormContainer)}
    >
      <input
        onFocus={() => {
          sendDataToGTM({
            event: "tcCustomEvent",
            eventCategory: "newsletter",
            eventLabel: getWindowHref(),
            eventAction: "focus",
          });
        }}
        onChange={handleInputChange}
        type="email"
        className={cx(isDark && styles.dark, isLarger && styles.larger)}
        placeholder="Enter your Email ID"
      />
      <button
        className={cx(isLarger && styles.wider)}
        aria-label="Sign Up for Newsletter"
      >
        sign up
      </button>
      <p
        style={{
          color: errorMsg ? "red" : "green",
        }}
      >
        {errorMsg || successMsg}
      </p>
    </form>
  );
}
