import { MouseEvent, ReactElement } from "react";

import styles from "./ComicLightbox.module.css";

export type Props = Readonly<{
  parentTestId?: string;
}>;

export default function ToggleContentColumnButton({
  parentTestId,
}: Props): ReactElement {
  const onToggleContentColumn = (e: MouseEvent) => {
    e.preventDefault();

    const contentLeft = document.querySelector(".contentLeft");
    const contentRight = document.querySelector(".contentRight");

    if (contentLeft || contentRight) {
      contentLeft?.classList.toggle("hide");
      contentRight?.classList.toggle("full");
    }
  };
  return (
    <div
      className={styles.toggleContentColumnButton}
      data-testid={`${parentTestId}-toggle-content-button`}
    >
      <button
        onClick={onToggleContentColumn}
        aria-label="Toggle Visibility of Content Columns"
      >
        Click to expand/close
      </button>
    </div>
  );
}
