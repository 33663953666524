import type {
  TRouteWithRedirect,
  TSuccessResponse,
} from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleCard } from "src/app/types/Article";

export interface EditorPickArticle {
  displayTitle: string;
  articleCard: ArticleCard | undefined;
}

export interface HomePageListing {
  heroArticles: ArticleCard[] | undefined;
  editorsPickArticle: EditorPickArticle | undefined;
  latestArticles: ArticleCard[] | undefined;
}

export default function useHomePageListing(): HomePageListing {
  const customContext: CustomContext<
    TRouteWithRedirect<HomePageListing | null, string>
  > = useRouteContext();
  const tSuccessResponse: TSuccessResponse<HomePageListing> =
    customContext.context as TSuccessResponse<HomePageListing>;
  const homePageListing: HomePageListing = tSuccessResponse.payload;
  return homePageListing;
}
