import { getWindowHref } from "@src/app/utils/helpers";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

export type ArticleProps = {
  headline: string;
  standfirst: string;
  heroImgUrl: string;
  authorName: string;
  authorUrl: string;
  createdAt?: string;
};

export default function MetaTagsArticle({
  headline,
  standfirst,
  heroImgUrl,
  authorName,
  authorUrl,
  createdAt,
}: ArticleProps): ReactElement {
  function getAuthorUrl(): string | undefined {
    if (typeof window != "undefined") {
      return new URL(authorUrl, window.location.origin).toString();
    }
  }

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getWindowHref(),
    },
    headline: headline,
    image: heroImgUrl,
    datePublished: createdAt,
    dateModified: createdAt,
    publisher: {
      "@type": "Organization",
      name: "Think China",
      logo: {
        "@type": "ImageObject",
        url: "/src/app/assets/logo@2x.svg",
      },
    },
    author: [
      {
        "@type": "Person",
        name: authorName,
        url: getAuthorUrl(),
      },
    ],
    description: standfirst,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
}
