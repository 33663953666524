import SignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./NewsletterWidget.module.css";

type Props = Readonly<{
  headerTitle: string;
  bodyText: string;
  testId?: string;
}>;

export default function NewsletterWidget({
  testId,
  headerTitle,
  bodyText,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.newsletterWidgetContainer)}>
      <div
        data-testid={`${testId}-innerContainer`}
        className={styles.innerContainer}
      >
        <div
          data-testid={`${testId}-headerContainer`}
          className={styles.headerContainer}
        >
          <h3 data-testid={`${testId}-headerTitle`}>{headerTitle}</h3>
        </div>
        <div data-testid={`${testId}-divider`} className={styles.divider}></div>
        <div
          data-testid={`${testId}-formContainer`}
          className={styles.formContainer}
        >
          <p data-testid={`${testId}-bodyText`}>{bodyText}</p>
          <SignupForm isDark={true} />
        </div>
      </div>
    </div>
  );
}
