import SignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import Column from "@src/app/components/Layouts/Column/Column";
import type { ReactElement } from "react";

import styles from "./NewsletterWidget.module.css";

type Props = Readonly<{
  isThinkCulture?: boolean;
  headerTitle: string;
  bodyText: string;
  testId?: string;
  icon: string;
}>;

export default function NewsletterWidget({
  isThinkCulture,
  testId,
  headerTitle,
  bodyText,
  icon,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.newsletterWidgetContainer}>
      <Column
        testId={`${testId}-iconContainer`}
        className={styles.iconContainer}
      >
        <img
          data-testid={`${testId}-thinkCultureIcon`}
          src={icon}
          alt="ThinkCulture Icon"
        />
      </Column>
      <Column className={styles.formContainer}>
        <h3 data-testid={`${testId}-headerTitle`}>{headerTitle}</h3>
        <p data-testid={`${testId}-bodyText`}>{bodyText}</p>
        <SignupForm isThinkCulture={isThinkCulture} isLarger />
      </Column>
    </div>
  );
}
