// CaaS Image API
// - https://sph.atlassian.net/wiki/spaces/PAPP/pages/1514865469/CaaS+how-tos#Image-optimisation-API
// - https://github.com/SPHTech-Applications/caas-platform-img-optimisation-lambda/blob/main/docs/quick-start.md#how-to-use-the-image-optimisation-api
const getCaasUrl = (baseUrl: string, width: number) => {
  return `${baseUrl}?${new URLSearchParams({ w: `${width}` }).toString()}`;
};

const roundForCache = (pixels: number) => Math.ceil(pixels / 100) * 100;

// ToDo: add unit tests
export const getCaasSrcs = (
  baseUrl: string,
  srcWidth: number,
  displayWidth: number,
) => {
  // the default image src points at a 1x image of the given display width
  const defaultWidth = Math.min(roundForCache(displayWidth), srcWidth);
  // widths for the srcset
  const widths = [
    // 0.5x to allow browser to load smaller image if display below the given display width
    roundForCache(defaultWidth * 0.5),
    // also include 1x in srcset
    defaultWidth,
    // add 2x image if source is high res enough
    defaultWidth * 2 <= srcWidth ? roundForCache(defaultWidth * 2) : undefined,
    // add 3x image if source is high res enough
    defaultWidth * 3 <= srcWidth ? roundForCache(defaultWidth * 3) : undefined,
  ];

  return {
    srcSet: widths
      .map(
        (width) => width && [getCaasUrl(baseUrl, width), `${width}w`].join(" "),
      )
      .filter(Boolean)
      .join(","),
    src: getCaasUrl(baseUrl, defaultWidth),
  };
};
