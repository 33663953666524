import ArticleHeader from "@src/app/components/Elements/Typography/ArticleHeader/ArticleHeader";
import Byline from "@src/app/components/Elements/Typography/Byline/Byline";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import type { ReactElement } from "react";

export type Props = Readonly<{
  headline: string;
  date: string;
  testId?: string;
}>;

export default function ContentTop({
  headline,
  date,
  testId = "content-top",
}: Props): ReactElement {
  return (
    <>
      <div data-testid={testId}>
        {/* Article Title */}
        <ArticleHeader>{headline}</ArticleHeader>
        {/* Published Date */}
        {date && <Byline>{formatPublishedDate(date)}</Byline>}
      </div>
    </>
  );
}
