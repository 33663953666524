import { AdUnitProps } from "@src/app/types/Ads.d";
import cx from "classnames";
import { ReactElement } from "react";

import { AdUnit } from "./AdUnit";
import styles from "./Advertisement.module.css";
import { useAdvertLoaded } from "./helpers";

export type AdvertisementProps = {
  rootClassName?: string;
  adUnitProps: AdUnitProps;
};

/**
 * This component renders an ad.
 * @param rootClassName - The root class name.
 * @param type - The type of the ad.
 * @param adUnitProps - The props for the ad.
 * @see https://www.imsph.sg/advertising-solutions/digital/imu/
 * @returns
 */
export default function Advertisement({
  rootClassName = "",
  adUnitProps,
}: AdvertisementProps): ReactElement {
  const isAdLoaded = useAdvertLoaded(adUnitProps.type, adUnitProps.id);

  return (
    <div
      className={cx(
        !isAdLoaded && styles.invisible,
        rootClassName,
        styles.center,
      )}
      style={{
        minHeight: "50px",
      }}
      data-testid="advertisement-element"
    >
      <AdUnit {...adUnitProps} />
    </div>
  );
}
