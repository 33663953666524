import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./ArticlePageLayout.module.css";

export type Props = Readonly<{
  testId?: string;
  contentTop?: ReactNode;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
}>;

export default function ArticlePageLayout({
  contentTop,
  contentLeft,
  contentRight,
  testId = "article-page-layout",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.articlePageLayoutWrapper)}>
      <article>
        <MainContainer>
          <Row>
            <Column testId={`${testId}-top`} className={styles.one}>
              {contentTop}
              <div className={styles.divider}></div>
            </Column>
          </Row>
          <Row>
            <Column
              testId={`${testId}-left`}
              className={cx(styles.two, "contentLeft")}
            >
              {contentLeft}
            </Column>
            <Column
              testId={`${testId}-right`}
              className={cx(styles.three, "contentRight")}
            >
              {contentRight}
            </Column>
          </Row>
        </MainContainer>
      </article>
    </div>
  );
}
