import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import Avatar from "@src/app/components/Elements/Avatar/Avatar";
import CategoryLabel, {
  Props as CategoryLabelProps,
} from "@src/app/components/Elements/CategoryLabel/CategoryLabel";
import ResponsiveImage from "@src/app/components/Elements/ResponsiveImage/ResponsiveImage";
import Paragraph from "@src/app/components/Elements/Typography/Paragraph/Paragraph";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import cx from "classnames";
import { ReactElement, useEffect, useRef, useState } from "react";

import styles from "./EditorsPick.module.css";

export type Props = Readonly<{
  editorsPickTitle: string;
  imgUrl: string;
  imgWidth: number;
  imgHeight: number;
  categoryLabelProps: CategoryLabelProps;
  paragraphContent: string;
  articleUrl: string;
  articleTitle: string;
  authorName: string;
  authorUrl: string;
  authorImgUrl: string;
  testId?: string;
}>;

export default function EditorsPick({
  editorsPickTitle,
  imgUrl,
  imgWidth,
  imgHeight,
  categoryLabelProps,
  paragraphContent,
  articleUrl,
  articleTitle,
  authorName,
  authorUrl,
  authorImgUrl,
  testId,
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();
  // Create a ref for the specific div
  const editorPickView = useRef<HTMLDivElement | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust threshold as needed (0.5 means at least 50% of the div should be visible)
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLoaded) {
          // Call sendDataToGTM method when the div becomes visible
          sendDataToGTM({
            event: "tcCustomEvent",
            eventCategory: "editors pick",
            eventLabel: "editors pick",
            eventAction: "view",
          });
          setIsLoaded(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const currentRef = editorPickView.current; // Copy the ref value to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isLoaded, sendDataToGTM]);

  return (
    <>
      <MainContainer>
        <div
          className={styles.editorsPickContainer}
          data-testid={testId}
          ref={editorPickView}
        >
          <SectionTitle>{editorsPickTitle}</SectionTitle>
          <div className={styles.editorsPickData}>
            <Row isVCenter={true}>
              {imgUrl && (
                <Column className={styles.colLeft}>
                  <a
                    href={articleUrl + "?ref=home-editors-pick"}
                    aria-label={`View details of ${articleTitle}`}
                  >
                    <ResponsiveImage
                      className={styles.fullWidth}
                      src={imgUrl}
                      alt={articleTitle}
                      srcWidth={imgWidth || 570}
                      srcHeight={imgHeight || 380}
                      loading="lazy"
                      displayWidth={570}
                    />
                  </a>
                </Column>
              )}
              <Column className={styles.colRight}>
                <div className={styles.editorsPickContent}>
                  <Row>
                    {authorImgUrl && authorName && authorUrl && (
                      <Column
                        className={cx(
                          styles.authorContainer,
                          styles.borderRight,
                        )}
                      >
                        <figure className={styles.figureAuthor}>
                          <Avatar alt={authorName} src={authorImgUrl} />
                          <figcaption>
                            <a
                              href={authorUrl}
                              aria-label={`Visit author's profile: ${authorName}`}
                            >
                              {authorName}
                            </a>
                          </figcaption>
                        </figure>
                      </Column>
                    )}

                    <Column className={styles.titleContainer}>
                      <CategoryLabel {...categoryLabelProps} />
                      <a
                        href={articleUrl + "?ref=home-editors-pick"}
                        aria-label={`Read article: ${articleTitle}`}
                      >
                        <h1 className={styles.title}>{articleTitle}</h1>
                      </a>
                    </Column>
                  </Row>

                  <Row>
                    <Column className={styles.description}>
                      <a
                        href={articleUrl + "?ref=home-editors-pick"}
                        aria-label={`Read article: ${paragraphContent}`}
                      >
                        <Paragraph>
                          <p>{paragraphContent}</p>
                        </Paragraph>
                      </a>
                    </Column>
                  </Row>
                </div>
              </Column>
            </Row>
          </div>
        </div>
      </MainContainer>
    </>
  );
}
