import { ContributorResData } from "@src/api/v1/contributor";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

type SearchQueryParams = {
  pathname: string;
};

export default function useContributorApi({
  pathname,
}: SearchQueryParams): SWRResponse<ContributorResData> {
  const queryString = `?pathname=${pathname}`;
  const swrRes: SWRResponse<ContributorResData> = useSWR<ContributorResData>(
    getApiFunctionUrl(`contributor${queryString}`),
    fetcher,
  );
  return swrRes;
}
