import { ReactElement, ReactNode } from "react";

import styles from "./HorizontalCenter.module.css";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode;
}>;

export default function HorizontalCenter({
  children,
  testId = "horizontal-center",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.center}>
      {children}
    </div>
  );
}
