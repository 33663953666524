import type { ReactElement } from "react";

import styles from "./ArticleBlurb.module.css";

type Props = Readonly<{
  children: string;
  testId?: string;
}>;

export default function ArticleBlurb({
  children,
  testId,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.articleBlurb}>
      <span>{children}</span>
    </div>
  );
}
