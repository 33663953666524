function getWindowOrigin(): string {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }
  return "http://localhost:3000";
}

const API_FUNCTION_BASE_PATH = "/_plat/api/v1/";

export default function getApiFunctionUrl(path: string): string {
  return getWindowOrigin() + API_FUNCTION_BASE_PATH + path;
}
