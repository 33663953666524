import { HERO_STORY_QUEUE_TYPE } from "@src/api/v1/storyQueue";
import { OG_SITE_NAME } from "@src/app/components/Blocks/MetaTags/Constants";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import EditorPickArticles from "@src/app/components/Modules/LandingPage/EditorsPick/EditorsPickArticles";
import HeroContent from "@src/app/components/Modules/LandingPage/HeroContent/HeroContent";
import PopularContentSectionOne from "@src/app/components/Modules/LandingPage/PopularContentSectionOne/PopularContentSectionOne";
import PopularContentSectionTwo from "@src/app/components/Modules/LandingPage/PopularContentSectionTwo/PopularContentSectionTwo";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useHomePageHook, {
  HomePageListing,
} from "@src/app/hooks/useHomePageListing";
import useLatestArticlesApi from "@src/app/hooks/useLatestArticlesApi";
import usePopularArticles from "@src/app/hooks/usePopularArticles";
import useStoryQueue from "@src/app/hooks/useStoryQueue";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { ArticleCard } from "@src/app/types/Article";
import { logEnd, logStart } from "@src/app/utils/helpers";
import { format } from "date-fns";

export default function HomePage() {
  const targeting = [
    {
      key: "page",
      value: "home",
    },
  ];

  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting({
      targeting,
    });

  const { heroArticles, editorsPickArticle, latestArticles }: HomePageListing =
    useHomePageHook();

  const date: string = format(new Date(), "yyyyMMdd");

  const timeStart = logStart();
  const { data: popularArticlesPayload } = usePopularArticles({
    size: 3,
    date,
  });

  logEnd("Homepage usePopularArticles", timeStart);

  const timeStartQueue = logStart();
  const { data: storyQueuePayload } = useStoryQueue({
    type: HERO_STORY_QUEUE_TYPE,
  });

  logEnd("Homepage useStoryQueue", timeStartQueue);

  const heroBannerArticles: ArticleCard[] =
    storyQueuePayload?.data.articles || heroArticles || [];

  const timeStartLatestArticles = logStart();

  const { data: latestArticlesPayload } = useLatestArticlesApi();

  logEnd("Homepage useLatestArticlesApi", timeStartLatestArticles);

  const alllatestArticlesList: ArticleCard[] =
    latestArticlesPayload?.data.results || latestArticles || [];

  // Extract IDs of articles in heroBannerArticles
  const heroBannerArticleIds = new Set(
    heroBannerArticles.map((article) => article.id),
  );

  // Filter out articles present in heroBannerArticles from latestArticlesList
  const latestArticlesList = alllatestArticlesList.filter(
    (article) => !heroBannerArticleIds.has(article.id),
  );

  const firstFoldSection = popularArticlesPayload?.data.length
    ? popularArticlesPayload.data.filter((_, index: number) => index < 3)
    : [];

  const secondFoldSection = latestArticlesList.length
    ? latestArticlesList.slice(0, 6)
    : []; // Articles with index 0 to 6

  const thirdFoldSection = latestArticlesList.length
    ? latestArticlesList.slice(6, 18)
    : []; // Articles with index 6 onwards

  return (
    <>
      <AdSettings />

      <MetaTags
        title={OG_SITE_NAME}
        description="ThinkChina - Get the latest insights on China's political, economic, socio-cultural and technology developments, seen through the eyes of Asian thought leaders and journalists."
        articlePageclass="page"
        slug="home"
        image="https://www.thinkchina.sg/assets/logo@2x.svg"
      />
      <GAData title="Homepage" level2="home" contenttype="index/listing page" />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial("homepage")}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1("homepage"),
        }}
      />

      <main>
        <div>
          {heroBannerArticles.length > 0 && (
            <HeroContent
              articles={heroBannerArticles}
              eventCategory="homepage banner "
            />
          )}
          {firstFoldSection.length > 0 && (
            <PopularContentSectionOne articles={firstFoldSection} />
          )}
          <PopularContentSectionTwo articles={secondFoldSection} />
          {editorsPickArticle && (
            <EditorPickArticles
              articles={thirdFoldSection}
              editorsPickArticle={editorsPickArticle}
            />
          )}
        </div>
      </main>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish("homepage")}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
