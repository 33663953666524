import NewsletterIconCulture from "@src/app/assets/images/newsletter-culture-icon.svg";
import NewsletterIcon from "@src/app/assets/images/newsletter-icon.svg";
import { OG_SITE_NAME } from "@src/app/components/Blocks/MetaTags/Constants";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import BreadCrumb from "@src/app/components/Elements/BreadCrumb/BreadCrumb";
import GAData from "@src/app/components/Elements/GAData/GAData";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import NewsletterWidget from "@src/app/components/Modules/NewsletterPage/NewsletterWidget/NewsletterWidget";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { removeLeadingSlash } from "@src/app/utils/helpers";
import { useLocation } from "react-router-dom";

import styles from "./Newsletter.module.css";

export default function NewsletterPage() {
  const { pathname } = useLocation();

  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting();

  const breadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Newsletter" },
  ];
  const NEWSLETTER_PROPS = {
    headerTitle: "THINKCHINA WEEKLY NEWSLETTER",
    bodyText: "Insights on China, right in your mailbox. Sign up now.",
    icon: NewsletterIcon,
  };
  const NEWSLETTER_CULTURE_PROPS = {
    isThinkCulture: true,
    headerTitle: "THINKCULTURE NEWSLETTER",
    bodyText:
      "Your fortnightly does of Chinese history, culture, and cartoons. Sign up now.",
    icon: NewsletterIconCulture,
  };

  return (
    <>
      <AdSettings />

      <MetaTags
        title={`Newsletter | ${OG_SITE_NAME}`}
        description=""
        ogType=""
        articlePageclass="page"
        slug={removeLeadingSlash(pathname)}
      />

      <GAData
        title="Newsletter"
        level2="newsletter"
        contenttype="index/listing page"
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial()}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <BreadCrumb items={breadcrumbItems} />
        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.LB1,
            slot: GoogleAdsSlotFactory.lb1(),
          }}
        />
        <Row>
          <Column>
            <div className={styles.newsletterContainer}>
              <SectionTitle>NEWSLETTER</SectionTitle>
              <div className={styles.splashBanner}></div>
              <Row>
                <div className={styles.newsletterContent}>
                  <NewsletterWidget {...NEWSLETTER_PROPS} />
                  <NewsletterWidget {...NEWSLETTER_CULTURE_PROPS} />
                </div>
              </Row>
            </div>
          </Column>
        </Row>
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish()}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
