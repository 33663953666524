import { AdTargetingType, AdvertisementTypeEnum } from "src/app/types/Ads.d";

/**
 * Configuration for Google Publisher Tag (GPT) slot sizes
 * Update new sizes here when needed e.g. midarticlespecial
 * @see https://docs.google.com/spreadsheets/d/1G0vL9aitTVpPSfy18w8EexNCk1lFrSuKbm2g58K8c-E/edit#gid=0
 */
export const adSlotSizes: Record<string, googletag.GeneralSize> = {
  lb1: [
    [970, 90],
    [728, 90],
    [970, 250],
    [320, 50],
    [320, 100],
    [320, 250],
    ["fluid"],
  ],
  imu1: [[1, 1], ["fluid"], [300, 250]],
  imu2: [
    [300, 250],
    [300, 600],
  ],
  midarticlespecial: [[1, 1], ["fluid"], [300, 250]],
  prestitial: [1, 1],
  catfish: [1, 1],
};

export const adSizeMappings: Partial<
  Record<AdvertisementTypeEnum, googletag.SizeMappingArray>
> = {
  lb1: [
    [
      [1024, 0],
      [[970, 90], [728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
};

export const adTargetings: Partial<
  Record<AdvertisementTypeEnum, AdTargetingType[]>
> = {
  lb1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  imu1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "5" },
  ],
  imu2: [
    { key: "pos", value: "2" },
    { key: "weight", value: "6" },
  ],
  midarticlespecial: [
    { key: "pos", value: "midarticlespecial" },
    { key: "weight", value: "4" },
  ],
  prestitial: [
    { key: "pos", value: "prestitial" },
    { key: "weight", value: "3" },
  ],
  catfish: [
    { key: "pos", value: "catfish" },
    { key: "weight", value: "3" },
  ],
};
