import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import HighlightContainer from "@src/app/components/Layouts/HighlightContainer/HighlightContainer";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import PopularThisMonthLayout from "@src/app/components/Layouts/PopularThisMonthLayout/PopularThisMonthLayout";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import { ArticleCard } from "@src/app/types/Article";

import styles from "./PopularContentSectionOne.module.css";

export type ArticleCardBlock = {
  articles: ArticleCard[];
  testId?: string;
};

export default function PopularContentSectionOne({
  articles,
  testId,
}: ArticleCardBlock) {
  return (
    <div className={styles.popularContentSectionOne} data-testid={testId}>
      <HighlightContainer>
        <MainContainer>
          <SectionTitle>Popular This Month</SectionTitle>
          <PopularThisMonthLayout>
            {articles.map((article: ArticleCard) => {
              const {
                articleName,
                id,
                blurb,
                category,
                authors,
                imgUrl,
                imgWidth,
                imgHeight,
                publishedDate,
              } = article;

              // Adding refcode for CTR tracking for hero-articles
              const articlePath = `${article.articlePath}?ref=home-popular-this-month`;

              return (
                <ArticleCardContent
                  key={id}
                  articlePath={articlePath}
                  articleTitle={articleName}
                  authors={authors}
                  categoryLabelProps={{
                    href: category.path,
                    label: category.name,
                  }}
                  date={formatPublishedDate(publishedDate)}
                  hasAuthorAndDate
                  hasCardShadow
                  hasSidePadding
                  isShortenArticleTitle
                  imgUrl={imgUrl}
                  imgWidth={imgWidth}
                  imgHeight={imgHeight}
                  paragraphContent={blurb}
                  testId="article-card-content-popular"
                />
              );
            })}
          </PopularThisMonthLayout>
        </MainContainer>
      </HighlightContainer>
    </div>
  );
}
