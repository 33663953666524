import type { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function Copyright(): ReactElement {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.copyrightText}>
      Copyright © {currentYear}&nbsp;
      <a
        href="https://sph.com.sg"
        aria-label="Visit  SPH Media Limited. Co.'s official website (opens in a new tab)"
        target="_blank"
        rel="noreferrer"
      >
        SPH Media Limited. Co.
      </a>
      &nbsp; Regn. No. 202120748H. All rights reserved.
    </div>
  );
}
