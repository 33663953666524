import type { ReactElement } from "react";

import styles from "./KeywordLink.module.css";

type Props = Readonly<{
  href: string;
  label: string;
}>;

export default function KeywordLink({ label, href }: Props): ReactElement {
  return (
    <a href={href} className={styles.link} aria-label={label}>
      <div className={styles.keywordLink}>{label}</div>
    </a>
  );
}
