import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import BlockTitle from "../../Elements/Typography/BlockTitle/BlockTitle";
import styles from "./RecommendedArticlesLayout.module.css";

export type Props = Readonly<{
  title?: string;
  testId?: string;
  children: ReactNode;
}>;

export default function RecommendedArticlesLayout({
  title = "Recommended Articles",
  children,
  testId,
}: Props): ReactElement {
  return (
    <div
      data-testid={`${testId}`}
      className={cx(styles.recommendedArticlesLayoutWrapper)}
    >
      <div className={cx(styles.title)}>
        <BlockTitle>{title}</BlockTitle>
      </div>

      <div className={cx(styles.cards)}>{children}</div>
    </div>
  );
}
