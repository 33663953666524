import type { ReactElement } from "react";

import styles from "./BlockTitle.module.css";

type Props = Readonly<{
  children: string;
  testId?: string;
}>;

export default function BlockTitle({ children, testId }: Props): ReactElement {
  return (
    <h2 data-testid={testId} className={styles.blockTitle}>
      <span>{children}</span>
    </h2>
  );
}
