import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faFacebookF, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ReactElement } from "react";

import styles from "./NavMenu.module.css";

export type Props = Readonly<{
  testId?: string;
}>;

export default function MobileNavMenuFooter({
  testId = "mobile-nav-menu",
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();

  return (
    <div
      data-testid={`${testId}-socialmedia-footer`}
      className={styles.socialMediaFooter}
    >
      <div>Follow us on:</div>
      <div
        data-testid={`${testId}-facebookIcon`}
        className={styles.facebookIcon}
      >
        <a
          href="https://www.facebook.com/ThinkChina/"
          aria-label="Visit ThinkChina on Facebook"
          onClick={() => {
            sendDataToGTM({
              event: "tcCustomEvent",
              eventCategory: "mobile navigation",
              eventLabel: "facebook",
              eventAction: "click",
            });
          }}
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </div>

      <div data-testid={`${testId}-twitterIcon`} className={styles.twitterIcon}>
        <a
          href="https://twitter.com/ThinkChinaSG"
          aria-label="Visit ThinkChina on Twitter"
          onClick={() => {
            sendDataToGTM({
              event: "tcCustomEvent",
              eventCategory: "mobile navigation",
              eventLabel: "twitter",
              eventAction: "click",
            });
          }}
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
      </div>
    </div>
  );
}
