import { SearchListingResData } from "@src/api/v1/search";
import useSWR, { SWRResponse } from "swr";

import getApiFunctionUrl from "../../helper/getApiFunctionUrl";
import fetcher from "../../utils/fetcher";

type SeachQueryParams = {
  keyword: string;
  page: number;
};

export default function useSearch({
  keyword,
  page,
}: SeachQueryParams): SWRResponse<SearchListingResData> {
  const queryString = `?keyword=${keyword}&page=${page}`;
  const swrRes: SWRResponse<SearchListingResData> =
    useSWR<SearchListingResData>(
      getApiFunctionUrl(`search${queryString}`),
      fetcher,
    );
  return swrRes;
}
