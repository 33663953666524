import type { ReactElement } from "react";

import styles from "./CategoryLabel.module.css";

export type Props = Readonly<{
  href: string;
  label: string;
}>;

export default function CategoryLabel({ label, href }: Props): ReactElement {
  return (
    <div className={styles.categoryLabel}>
      <a
        href={href}
        className={styles.link}
        aria-label={`Explore articles in the category: ${label}`}
      >
        {label}
      </a>
    </div>
  );
}
