import type { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function FooterInfo(): ReactElement {
  return (
    <div className={styles.introText}>
      <b>ThinkChina</b> is an English language e-magazine focused on
      China&nbsp;and powered by&nbsp;
      <a
        href="https://sph.com.sg"
        className={styles.textLinks}
        aria-label="Visit SPH Media Limited's official website"
      >
        SPH Media Limited&apos;s
      </a>{" "}
      flagship Chinese daily{" "}
      <a
        href="https://zaobao.com.sg"
        className={styles.textLinks}
        aria-label="Visit Lianhe Zaobao's official website"
      >
        Lianhe Zaobao
      </a>
      .
    </div>
  );
}
