import { ContributorListingResData } from "@src/api/v1/contributors";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

type SearchQueryParams = {
  page: number;
};

export default function useContributorListingApi({
  page,
}: SearchQueryParams): SWRResponse<ContributorListingResData> {
  const queryString = `?page=${page}`;
  const swrRes: SWRResponse<ContributorListingResData> =
    useSWR<ContributorListingResData>(
      getApiFunctionUrl(`contributors${queryString}`),
      fetcher,
    );
  return swrRes;
}
