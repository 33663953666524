import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import cx from "classnames";
import Cookies, { CookieAttributes } from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import styles from "./Advertisement.module.css";
import { adSlotSizes, adTargetings } from "./constants";
import { generateAdvertElementId, useAdvertLoaded } from "./helpers";

const TYPE = AdvertisementTypeEnum.PRESTITIAL;
const ID = generateAdvertElementId(TYPE);
const TOPOVERLAY_VALIDITY = 4;

export type PrestitialAdProps = {
  rootClassName?: string;
  slot: string;
  isPrestitialEnabled: boolean | undefined;
  topOverlayImpressions: number | undefined;
};

export default function PrestitialAd({
  rootClassName,
  slot,
  isPrestitialEnabled,
  topOverlayImpressions,
}: PrestitialAdProps) {
  const topoverlayDisplayedCookie = Cookies.get("topoverlayDisplayed");
  const topOverlayImpressionsServedCookie = Cookies.get(
    "topOverlayImpressionsServed",
  );
  let impressionsServed = topOverlayImpressionsServedCookie
    ? parseInt(topOverlayImpressionsServedCookie)
    : 0;

  const isAdLoaded = useAdvertLoaded(TYPE, ID);
  const [error, setError] = useState("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const adSlotSize = adSlotSizes[TYPE];
  const adTarget = adTargetings[TYPE];

  const handleDisplayAd = useCallback(() => {
    try {
      googletag.cmd.push(function () {
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot) {
            if (_slot.getSlotElementId()) {
              if (_slot.getSlotElementId() === ID) {
                googletag.destroySlots([_slot]);
              }
            }
          });

        const _adSlot = googletag.defineSlot(slot, adSlotSize, ID);

        if (_adSlot) {
          _adSlot.addService(googletag.pubads());

          adTarget?.forEach(({ key, value }) => {
            _adSlot.setTargeting(key, value);
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots["prestitial"] = _adSlot;

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          // Store the ad slot for later use.
          setAdSlot(_adSlot);
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, adSlotSize, adTarget]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      isPrestitialEnabled != undefined &&
      topOverlayImpressions != undefined
    ) {
      if (slot && !adSlot) {
        const isDfpTest = window.location.search.includes("dfp_preview");
        // window.dfp_preview_ids != "" &&
        // window.dfp_preview_ids != undefined &&
        // // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        // window.dfp_preview_ids?.length != 0;
        if (
          ((impressionsServed < topOverlayImpressions &&
            typeof topoverlayDisplayedCookie === "undefined") ||
            isDfpTest) &&
          screen.width > 767 &&
          isPrestitialEnabled
        ) {
          handleDisplayAd();

          const expiry = new Date();
          expiry.setTime(
            expiry.getTime() + TOPOVERLAY_VALIDITY * 60 * 60 * 1000,
          );
          const cookieOptions: CookieAttributes = {
            path: "/",
            expires: expiry,
          };

          window.show_overlay = 1;
          impressionsServed++;

          if (impressionsServed === topOverlayImpressions) {
            Cookies.set("topoverlayDisplayed", "yes", cookieOptions);
            Cookies.set("topOverlayImpressionsServed", "0", cookieOptions);
          } else {
            Cookies.set(
              "topOverlayImpressionsServed",
              impressionsServed.toString(),
              cookieOptions,
            );
          }
        } else if (!isPrestitialEnabled) {
          console.log("isPrestitialEnabled", isPrestitialEnabled);
          document.documentElement.classList.remove("hasoverlay");
        }

        // let smx_prebid know all slots (waiting for last prestitial slot) are loaded
        window.load_ad_tag = 1;
      }
    }
  }, [
    slot,
    handleDisplayAd,
    adSlot,
    impressionsServed,
    topoverlayDisplayedCookie,
    isPrestitialEnabled,
    topOverlayImpressions,
  ]);

  useEffect(() => {
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <>
      <div className={cx(rootClassName, !isAdLoaded && styles.invisible)}>
        {!error ? <div id={ID}></div> : error}
      </div>
    </>
  );
}
