import cx from "classnames";
import type { ReactElement, ReactNode } from "react";

import styles from "./Byline.module.css";

type Props = Readonly<{
  children: ReactNode;
  isDark?: boolean;
  withSpacing?: boolean;
  testId?: string;
}>;

export default function Byline({
  children,
  isDark = false,
  withSpacing = false,
  testId,
}: Props): ReactElement {
  return (
    <span
      data-testid={testId}
      className={cx(
        styles.byline,
        isDark && styles.dark,
        withSpacing && styles.spacing,
      )}
    >
      {children}
    </span>
  );
}
