import cx from "classnames";
import type { ReactElement, ReactNode } from "react";

import styles from "./Paragraph.module.css";

type Props = Readonly<{
  children: ReactNode;
  isShorten?: boolean;
  isLarger?: boolean;
  hasMargin?: boolean;
  testId?: string;
}>;

export default function Paragraph({
  children,
  isShorten,
  isLarger,
  hasMargin,
  testId,
}: Props): ReactElement {
  return (
    <div
      data-testid={testId}
      className={cx(
        styles.paragraph,
        true === isShorten ? styles.shorten : "",
        true === isLarger ? styles.larger : "",
        true === hasMargin ? styles.margin : "",
      )}
    >
      {children}
    </div>
  );
}
