import { useScript } from "@sphtech/web2-core/hooks";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import MetaTagsArticle from "@src/app/components/Blocks/MetaTagsArticle/MetaTagsArticle";
import NotFound from "@src/app/components/Blocks/NotFound/NotFound";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import BreadCrumb from "@src/app/components/Elements/BreadCrumb/BreadCrumb";
import GAData from "@src/app/components/Elements/GAData/GAData";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import ArticleContent from "@src/app/components/Modules/ArticlePage/ArticleContent/ArticleContent";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import useArticle, { Article } from "@src/app/hooks/useArticle";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useRecommendedArticlesApi from "@src/app/hooks/useRecommendedArticlesApi";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { ArticleCard } from "@src/app/types/Article";
import {
  capitalizeFirstLetter,
  removeLeadingSlash,
  replaceSpecialChars,
} from "@src/app/utils/helpers";
import { logEnd, logStart } from "@src/app/utils/helpers";
import { useLocation, useParams } from "react-router-dom";

import { getAuthorNames, getTagNames } from "./helpers";

function ArticlePageContent({
  article,
  grapeshot,
}: {
  article: Article;
  grapeshot: string;
}) {
  const { topic } = useParams();
  const { pathname } = useLocation();
  const timeStart = logStart();
  const { data: apiPayloadRecommendedArticles, isLoading } =
    useRecommendedArticlesApi({
      topic: topic || "",
      pathname,
    });
  logEnd("Article useRecommendedArticlesApi", timeStart);

  const recommendedArticles =
    apiPayloadRecommendedArticles?.data.results || ([] as ArticleCard[]);

  const {
    id,
    headline,
    standfirst,
    heroImgUrl,
    heroImgCaption,
    heroImgWidth,
    heroImgHeight,
    authors,
    translators,
    publishedDate,
    bodyElements,
    tags,
    category,
    breadCrumbs,
    displayType,
    seoFields,
    ogFields,
    ogImageUrl,
  }: Article = article;

  const isArticleAComic = displayType == "comic";

  const authorNames = getAuthorNames(authors);
  const tagNames = getTagNames(tags);

  const tagsNameArray = tags.map((tag) => tag.name);

  const targeting = [
    {
      key: "page",
      value: "article",
    },
    {
      key: "tctags",
      value: replaceSpecialChars(tagsNameArray.join(",")),
    },
    {
      key: "tcarticleid",
      value: id,
    },
    {
      key: "gs_channels",
      value: grapeshot,
    },
  ];

  const {
    isPrestitialEnabled,
    topOverlayImpressions,
    isCatfishEnabled,
    isOutbrainEnabled,
  } = useGlobalAdSetting({
    targeting,
  });

  const isCulture = ["cartoon", "history", "culture"].includes(category);

  /* Newsletter Widget */
  const nlHeaderTitle = isCulture
    ? "Get the ThinkCulture Newsletter"
    : "Get the ThinkChina newsletter";
  const nlBodyText = isCulture
    ? "Your fortnightly dose of Chinese history, culture and cartoons. Sign up now."
    : "Insights on China, right in your mailbox. Sign up now.";
  const nlIsDark = isCulture ? false : true;
  const nlIsThinkCulture = isCulture;

  const articleContentProps = {
    headline: headline,
    date: publishedDate,
    authors: authors,
    translators: translators,
    category: category,
    nlIsDark: nlIsDark,
    nlIsThinkCulture: nlIsThinkCulture,
    nlHeaderTitle: nlHeaderTitle,
    nlBodyText: nlBodyText,
    standfirst: standfirst,
    heroImgUrl: heroImgUrl,
    heroImgCaption: heroImgCaption,
    heroImgWidth: heroImgWidth,
    heroImgHeight: heroImgHeight,
    bodyElements: bodyElements,
    tags: tags,
    recommendedArticles: recommendedArticles,
    recommendedArticlesIsLoading: isLoading,
    isArticleAComic: isArticleAComic,
    isOutbrainEnabled: isOutbrainEnabled,
    pathname: pathname,
  };

  const metaTitle = seoFields?.metaTitle.trim() as string;
  const metaDesc = seoFields?.metaDescription.trim() as string;
  const ogTitle = ogFields?.ogTitle.trim() as string;
  const ogDesc = ogFields?.ogDescription.trim() as string;

  const title = metaTitle != "" ? metaTitle : headline;
  const desc = metaDesc != "" ? metaDesc : standfirst;
  const otitle = ogTitle != "" ? ogTitle : headline;
  const odesc = ogDesc != "" ? ogDesc : standfirst;

  const displayedTopic = capitalizeFirstLetter(category);

  return (
    <>
      <AdSettings />

      <MetaTags
        title={`${title}, ${displayedTopic} News - ThinkChina`}
        description={desc}
        ogType="website"
        articlePageclass="page"
        slug={removeLeadingSlash(pathname)}
        image={heroImgUrl}
        keywords={tagsNameArray}
        author={authors[0]?.name}
        section={category}
        isArticlePage={true}
        ogTitle={otitle}
        ogDescription={odesc}
        ogImage={ogImageUrl}
        canonical={seoFields?.canonicalUrl}
      />

      <MetaTagsArticle
        headline={headline}
        standfirst={standfirst}
        heroImgUrl={heroImgUrl}
        authorName={authors[0]?.name || ""}
        authorUrl={authors[0]?.url || ""}
        createdAt={publishedDate}
      />
      <GAData
        title={headline}
        level2={category}
        contenttype="article"
        articleid={id}
        cue_articleid={id}
        author={authorNames}
        keyword={tagNames}
        pubdate={formatPublishedDate(publishedDate, "yyyy-MM-dd HH:mm:ss")}
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial(category)}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <BreadCrumb items={breadCrumbs} />

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.LB1,
            slot: GoogleAdsSlotFactory.lb1(category),
          }}
        />
      </MainContainer>

      <ArticleContent {...articleContentProps} />

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(category)}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}

export default function ArticlePage() {
  /* Outbrain */
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });

  const tResponse = useArticle();
  const statusCode = tResponse.statusCode;
  const payload = tResponse.payload;

  if (statusCode != 200 || !payload || typeof payload === "string") {
    return <NotFound />;
  }
  const article = payload.article || ({} as Article);
  const grapeshot = payload.grapeshot || "[]"; // string
  return <ArticlePageContent article={article} grapeshot={grapeshot} />;
}
