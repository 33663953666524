import ArticlePageLayout from "@src/app/components/Layouts/ArticlePageLayout/ArticlePageLayout";
import { ResolvedCUEElement } from "@src/app/hooks/useArticle/elements";
import { ArticleCard, Author } from "@src/app/types/Article";
import { CUETags } from "@src/app/types/Opensearch";
import type { ReactElement } from "react";

import ContentLeft from "./ContentLeft";
import ContentRight from "./ContentRight";
import ContentTop from "./ContentTop";

export type Props = Readonly<{
  headline: string;
  date: string;
  authors: Author[];
  translators?: string;
  category?: string;
  nlIsDark?: boolean;
  nlIsThinkCulture?: boolean;
  nlHeaderTitle?: string;
  nlBodyText?: string;
  standfirst?: string;
  heroImgUrl?: string;
  heroImgCaption?: string | undefined;
  heroImgWidth?: number;
  heroImgHeight?: number;
  bodyElements: ResolvedCUEElement[];
  tags: CUETags[];
  recommendedArticles?: ArticleCard[];
  recommendedArticlesIsLoading?: boolean;
  isArticleAComic?: boolean;
  isOutbrainEnabled?: boolean;
  pathname: string;
  testId?: string;
}>;

export default function ArticleContent({
  headline,
  date,
  authors,
  translators,
  category,
  nlIsDark,
  nlIsThinkCulture,
  nlHeaderTitle,
  nlBodyText,
  standfirst,
  heroImgUrl,
  heroImgCaption,
  heroImgWidth,
  heroImgHeight,
  bodyElements,
  tags,
  recommendedArticles,
  recommendedArticlesIsLoading,
  isArticleAComic,
  isOutbrainEnabled,
  pathname,
  testId = "article-content",
}: Props): ReactElement {
  const contentTopProps = {
    testId: `${testId}-top`,
    headline: headline,
    date: date,
  };

  const contentLeftProps = {
    testId: `${testId}-left`,
    authors: authors,
    translators: translators,
    category: category,
    nlIsDark: nlIsDark,
    nlIsThinkCulture: nlIsThinkCulture,
    nlHeaderTitle: nlHeaderTitle,
    nlBodyText: nlBodyText,
  };

  const contentRightProps = {
    testId: `${testId}-right`,
    nlIsDark: nlIsDark,
    nlIsThinkCulture: nlIsThinkCulture,
    nlHeaderTitle: nlHeaderTitle,
    nlBodyText: nlBodyText,
    standfirst: standfirst,
    heroImgUrl: heroImgUrl,
    heroImgCaption: heroImgCaption,
    heroImgWidth: heroImgWidth,
    heroImgHeight: heroImgHeight,
    bodyElements: bodyElements,
    //category: category,
    tags: tags,
    recommendedArticles: recommendedArticles,
    recommendedArticlesIsLoading: recommendedArticlesIsLoading,
    isArticleAComic: isArticleAComic,
    isOutbrainEnabled: isOutbrainEnabled,
    pathname: pathname,
  };

  return (
    <ArticlePageLayout
      testId={testId}
      contentTop={<ContentTop {...contentTopProps} />}
      contentLeft={<ContentLeft {...contentLeftProps} />}
      contentRight={<ContentRight {...contentRightProps} />}
    />
  );
}
