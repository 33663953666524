import ShareButtons from "@src/app/components/Blocks/ShareButtons/ShareButtons";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import Byline from "@src/app/components/Elements/Typography/Byline/Byline";
import { getAuthorCards } from "@src/app/pages/Article/helpers";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { Author } from "@src/app/types/Article";
import type { ReactElement } from "react";

import NewsletterWidget from "../NewsletterWidget/NewsletterWidget";
import styles from "./ArticleContent.module.css";

export type Props = Readonly<{
  authors: Author[];
  translators?: string;
  category?: string;
  nlIsDark?: boolean;
  nlIsThinkCulture?: boolean;
  nlHeaderTitle?: string | undefined;
  nlBodyText?: string | undefined;
  testId?: string;
}>;

export default function ContentLeft({
  authors,
  translators,
  category = "",
  nlIsDark,
  nlIsThinkCulture,
  nlHeaderTitle = "",
  nlBodyText = "",
  testId = "content-left",
}: Props): ReactElement {
  const authorCards = getAuthorCards(authors);

  return (
    <>
      <div data-testid={testId}>
        {/* Author Cards */}
        {authorCards}

        {/* Translator */}
        {translators && (
          <div className={styles.translator}>
            <Byline isDark withSpacing>
              Translated by {translators}
            </Byline>
          </div>
        )}

        {/* Share Buttons */}
        <ShareButtons />

        {/* Ads IMU2 */}
        <Advertisement
          rootClassName={styles.adImu2}
          adUnitProps={{
            type: AdvertisementTypeEnum.IMU2,
            slot: GoogleAdsSlotFactory.imu2(category),
          }}
        />

        {/* Newsletter Widget */}
        <div className={styles.newsletterDesktop}>
          <NewsletterWidget
            isDark={nlIsDark}
            headerTitle={nlHeaderTitle}
            bodyText={nlBodyText}
            isThinkCulture={nlIsThinkCulture}
          />
        </div>
      </div>
    </>
  );
}
