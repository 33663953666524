import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";

import styles from "./ScrollToTop.module.css";

function goTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default function ScrollToTop(): ReactElement {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  return (
    <button
      className={cx(styles.returnToTop, isVisible && styles.visible)}
      onClick={goTop}
      data-testid="scroll-to-top-button"
      aria-label="Return to Top"
      tabIndex={0}
    >
      <FontAwesomeIcon icon={faAngleUp} />
    </button>
  );
}
