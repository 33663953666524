import type { ReactElement } from "react";

import styles from "./ArticleHeader.module.css";

type Props = Readonly<{
  children: string;
  testId?: string;
}>;

export default function ArticleHeader({
  children,
  testId,
}: Props): ReactElement {
  return (
    <h1 data-testid={testId} className={styles.header}>
      {children}
    </h1>
  );
}
