import LogoImage from "@src/app/assets/logo@2x.svg";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import cx from "classnames";
import type { ReactElement } from "react";

import Copyright from "./Copyright";
import styles from "./Footer.module.css";
import FooterInfo from "./FooterInfo";
import FooterLinks from "./FooterLinks";
import PoweredBy from "./PoweredBy";
import SocialLinks from "./SocialLinks";

export default function Footer(): ReactElement {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <MainContainer>
          <div className={styles.footerTopContent}>
            <div className={styles.footerLogo}>
              <a href="/" aria-label="Go to ThinkChina Home">
                <img
                  src={LogoImage}
                  className={cx(styles.logo, "lazyload")}
                  alt="ThinkChina logo"
                  width="510"
                  height="154"
                  loading="lazy"
                />
              </a>
            </div>
            <SocialLinks />
          </div>
          <div className={styles.footerDivider}></div>
        </MainContainer>
      </div>
      <div className={styles.footerCenter}>
        <MainContainer>
          <div className={styles.footerCenterRow}>
            <FooterInfo />
            <FooterLinks />
          </div>
        </MainContainer>
      </div>
      <div className={styles.poweredBy}>
        <MainContainer>
          <PoweredBy />
        </MainContainer>
      </div>
      <div className={styles.copyrightContainer}>
        <MainContainer>
          <Copyright />
        </MainContainer>
      </div>
    </footer>
  );
}
