import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleListing } from "src/app/types/Article";

export type Topic =
  | "politics"
  | "economy"
  | "technology"
  | "history"
  | "society"
  | "culture"
  | "cartoon";

export function isOfTopic(keyInput: string): keyInput is Topic {
  return [
    "politics",
    "economy",
    "technology",
    "history",
    "society",
    "culture",
    "cartoon",
  ].includes(keyInput);
}

export default function useSectionListingContext(): TRouteWithoutRedirect<
  ArticleListing | null,
  string
> {
  const customContext: CustomContext<
    TRouteWithoutRedirect<ArticleListing | null, string>
  > = useRouteContext();

  const tResponse: TRouteWithoutRedirect<ArticleListing | null, string> =
    customContext.context;

  return tResponse;
}
