import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import { getWindowHref } from "@src/app/utils/helpers";
import cx from "classnames";
import type { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import { LinkProps, MISC_LIST, TOPICS_LIST } from "../constants";
import Search from "../Search/Search";
import styles from "./NavMenu.module.css";

export type Props = Readonly<{
  testId?: string;
}>;

export default function NavList({
  testId = "mobile-nav-menu",
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const { topic: currentTopic, tagName } = useParams();
  const currentPath = currentTopic || tagName;
  const location = useLocation();

  return (
    <ul
      className={cx(styles.menuNavList)}
      data-testid={`${testId}-menuNavList`}
    >
      <li>
        <Search hasText className={styles.searchIcon} />
      </li>
      {MISC_LIST.map((misc: LinkProps, index: number) => {
        return (
          <li
            data-testid={`${testId}-navLinks`}
            key={index}
            className={cx(location.pathname === misc.url && styles.active)}
          >
            <InternalLink
              to={misc.url + "?ref=header-page"}
              onClick={() => {
                if (misc.title === "newsletter") {
                  sendDataToGTM({
                    event: "tcCustomEvent",
                    eventCategory: misc.title.toLowerCase(),
                    eventLabel: getWindowHref(),
                    eventAction: "click",
                  });
                }
              }}
            >
              {misc.title}
            </InternalLink>
          </li>
        );
      })}
      <div data-testid={`${testId}-divider`} className={styles.divider}></div>
      {TOPICS_LIST.map((topic: LinkProps, index: number) => {
        return (
          <li
            key={index}
            data-testid={`${testId}-topicLinks`}
            className={cx(currentPath === topic.title && styles.active)}
          >
            <InternalLink
              to={topic.url}
              onClick={() => {
                sendDataToGTM({
                  event: "tcCustomEvent",
                  eventCategory: "mobile navigation",
                  eventLabel: topic.title.toLowerCase(),
                  eventAction: "click",
                });
              }}
            >
              {topic.title}
            </InternalLink>
          </li>
        );
      })}
    </ul>
  );
}
