import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./ComicLightbox.module.css";

export type Props = Readonly<{
  parentTestId?: string;
}>;

export default function ComicTextWrapper({
  parentTestId,
}: Props): ReactElement {
  return (
    <div
      className={cx(styles.textWrapper)}
      data-testid={`${parentTestId}-text-wrapper`}
    >
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
      <span>To read in panels, click on image.</span>
    </div>
  );
}
