import ResponsiveImage from "@src/app/components/Elements/ResponsiveImage/ResponsiveImage";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./ComicLightbox.module.css";

export type Props = Readonly<{
  src: string;
  alt: string;
  width: number;
  height: number;
  parentTestId: string;
}>;

export default function LightGalleryItem({
  src,
  alt,
  width,
  height,
  parentTestId,
}: Props): ReactElement {
  return (
    <li>
      <a
        href={src}
        className="galleryItem"
        data-lg-size="900-1200"
        data-testid={`${parentTestId}-gallery-item`}
        aria-label={`View larger image of ${alt}`}
      >
        <ResponsiveImage
          className={cx(styles.imgResponsive, "ls-is-cached", "lazyloaded")}
          src={src}
          alt={alt}
          srcWidth={width || 1200}
          srcHeight={height || 1698}
          loading="lazy"
          displayWidth={1200}
        />
      </a>
    </li>
  );
}
