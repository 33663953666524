export function removeTrailingSlash(s: string): string {
  return s.endsWith("/") ? s.substring(0, s.length - 1) : s;
}

export function removeLeadingSlash(s: string): string {
  return s.startsWith("/") ? s.slice(1) : s;
}

export function getUrlPathWithoutPage(s: string): string {
  const urlPath = s.split("/page");
  return urlPath.length > 1 ? urlPath[0] : s;
}

export function getWindowOrigin() {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }
}

export function getPaginationFrom(page: string, size: number) {
  const pageParam: number = (page || 0) as number;
  return pageParam ? size * pageParam : 0;
}

export function formatSlugToTagID(slug: string) {
  return `tag:thinkchina-keywords@sph.com.sg,*:${slug}`;
}

export function getWindowHref() {
  if (typeof window !== "undefined") {
    return window.location.href;
  }
}

export function replaceSpecialChars(input: string) {
  // regex: remove non-alphanumeric chars except spaces and comma and replace spaces with dash
  return input.toLowerCase().replace(/[^\w\s,]+|\s+/g, "-");
}

export function capitalizeFirstLetter(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function logStart() {
  return performance.now();
}

export function logEnd(
  apiName: string,
  timeStart: number,
  apiEndpoint?: string,
  query?: unknown,
  traceId?: string | null,
) {
  const timeEnd = performance.now();
  console.log({
    apiName: apiName,
    time: timeEnd - timeStart,
    apiEndpoint: apiEndpoint || "",
    query: JSON.stringify(query || {}),
    traceId: traceId || "",
  });
}
