import { SectionListingResData } from "@src/api/v1/topics";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

export type Topic =
  | "politics"
  | "economy"
  | "technology"
  | "history"
  | "society"
  | "culture"
  | "cartoon";

export function isOfTopic(keyInput: string): keyInput is Topic {
  return [
    "politics",
    "economy",
    "technology",
    "history",
    "society",
    "culture",
    "cartoon",
  ].includes(keyInput);
}

type TopicQueryParams = {
  topicUniqueName: string;
  page: number;
};

export default function useSectionListingApi({
  topicUniqueName,
  page,
}: TopicQueryParams): SWRResponse<SectionListingResData> {
  const queryString = `/${topicUniqueName}?page=${page}`;
  const swrRes: SWRResponse<SectionListingResData> =
    useSWR<SectionListingResData>(
      getApiFunctionUrl(`topics${queryString}`),
      fetcher,
    );
  return swrRes;
}
