import Footer from "@src/app/components/Modules/Footer/Footer";
import Header from "@src/app/components/Modules/Header/Header";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "./PageLayout.module.css";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

export type Props = Readonly<{
  testId?: string;
  children: ReactElement;
}>;

/*

 * Reusable page layout for all ThinkChina pages. Comes out of the box with composed Header, Footer, ScrollToTop and gives main content proper web semantics
  
 * @param {ReactElement} children - Any Block, Module React Element 
 * @param {String} testId - Unique string used to target elements when testing
 * @returns {ReactElement} - A React Element composed with Header, ScrollToTop, Footer 

 */

export default function PageLayout({
  children,
  testId = "page-layout",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.pageLayout)}>
      <Header />
      <main>{children}</main>
      <Footer />
      <ScrollToTop />
    </div>
  );
}
