import { fetchContext } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import App from "./App";
import NotFound from "./components/Blocks/NotFound/NotFound";
import AboutUsPage from "./pages/AboutUs/AboutUs";
import ArticlePage from "./pages/Article/Article";
import ContributorPage from "./pages/Contributor/Contributor";
import ContributorListingPage from "./pages/ContributorListing/ContributorListing";
import HomePage from "./pages/Home/Home";
import NewsletterPage from "./pages/Newsletter/Newsletter";
import SearchPage from "./pages/Search/Search";
import SectionListingPage from "./pages/SectionListing/SectionListing";
import TagListingPage from "./pages/TagListing/TagListing";
import { getDependentPaths } from "./utils/routes";

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/search",
        element: <SearchPage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:slug",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:one/:two",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:one/:two/:three",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:one/:two/:three/:four",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:one/:two/:three/:four/:five",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:one/:two/:three/:four/:five/:six",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:topic/:one/:two/:three/:four/:five/:six/:seven",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/tags/:tagName",
        element: <TagListingPage />,
        loader: fetchContext,
      },
      {
        path: "/:topic",
        element: <SectionListingPage />,
        loader: fetchContext,
      },
      {
        path: "/about-us",
        element: <AboutUsPage />,
      },
      {
        path: "/contributors",
        element: <ContributorListingPage />,
        loader: fetchContext,
      },
      {
        path: "/contributors/:name",
        element: <ContributorPage />,
        loader: fetchContext,
      },
      {
        path: "/newsletter",
        element: <NewsletterPage />,
      },
      {
        index: true,
        element: <HomePage />,
        loader: fetchContext,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);

export { getDependentPaths };
