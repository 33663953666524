import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

export interface Props extends LinkProps {
  children: ReactNode;
}

const InternalLink: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Link reloadDocument {...rest}>
      {children}
    </Link>
  );
};

export default InternalLink;
