import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import cx from "classnames";
import { type ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { LinkProps, TOPICS_LIST } from "./constants";
import styles from "./Header.module.css";
import Search from "./Search/Search";

export type Props = Readonly<{
  testId?: string;
}>;

export default function DesktopQuickLinks({
  testId = "desktop-quick-links",
}: Props): ReactElement {
  const { topic: currentTopic, tagName } = useParams();
  const currentPath = currentTopic || tagName;
  const sendDataToGTM = useGTMDispatch();

  let isMobile = false;
  if (typeof window != "undefined") {
    const mediaQueryList = window.matchMedia("(max-width: 767px)");
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (mediaQueryList && mediaQueryList.matches) {
      isMobile = mediaQueryList.matches;
    }
  }

  const eventCategory = isMobile ? "mobile navigation" : "navigation";

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // recalculate change in distance from top (eg. when skinner ads are added)
      const navbarHeight =
        typeof window.document !== "undefined"
          ? (window.document.getElementById("navbar-1")?.offsetHeight as number)
          : 93;
      const distanceFromTop =
        typeof window.document !== "undefined"
          ? (window.document.getElementById("navbar-1")?.getBoundingClientRect()
              .top as number)
          : navbarHeight;

      if (
        // scroll exceeds navbar-1
        window.scrollY >= navbarHeight &&
        // navbar-1 is hidden
        distanceFromTop < -navbarHeight
      ) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <div
      data-testid={`${testId}-quickLinks`}
      className={cx(isSticky && styles.sticky, styles.quickLinks)}
      id="navbar-2"
    >
      <MainContainer testId={testId}>
        <div
          data-testid={`${testId}-quickLinksInner`}
          className={cx(styles.quickLinksInner)}
        >
          <div
            data-testid={`${testId}-topicsListWrapper`}
            className={cx(styles.topicsListWrapper)}
          >
            <ul
              data-testid={`${testId}-topicsList`}
              className={cx(styles.topicsList)}
            >
              {TOPICS_LIST.map((topic: LinkProps, index: number) => {
                return (
                  <li
                    key={index}
                    data-testid={`${testId}-topicLinks`}
                    className={cx(
                      styles.topicLinks,
                      currentPath === topic.title && styles.active,
                    )}
                  >
                    <InternalLink
                      to={topic.url + "?ref=header-page"}
                      onClick={() => {
                        sendDataToGTM({
                          event: "tcCustomEvent",
                          eventCategory: eventCategory,
                          eventAction: "click",
                          eventLabel: topic.title.toLowerCase(),
                        });
                      }}
                    >
                      {topic.title}
                    </InternalLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <Search data-testid={testId} className={styles.searchIcon} isDark />
        </div>
      </MainContainer>
    </div>
  );
}
