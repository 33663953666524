import "@fortawesome/fontawesome-svg-core/styles.css";

import { faFacebookF, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function SocialLinks(): ReactElement {
  return (
    <div className={styles.footerSocial}>
      <a
        href="https://www.facebook.com/ThinkChina/?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Facebook"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a
        href="https://twitter.com/ThinkChinaSG?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Twitter"
      >
        <FontAwesomeIcon icon={faXTwitter} />
      </a>
    </div>
  );
}
