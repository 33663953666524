import { getCaasSrcs } from "@src/app/types/Caas.d";
import cx from "classnames";
import React from "react";

import styles from "./ResponsiveImage.module.css";

const ResponsiveImage: React.FC<{
  /** the url of the image, should be a CaaS image url to support srcset optimization */
  src: string;
  /** img alt attribute, requiered for accessibility, can be empty string for decorative images */
  alt: string | undefined;
  /** the width of the image referenced in src  */
  srcWidth: number;
  /** the height of the image referenced in src  */
  srcHeight: number;
  /** the width the 1x image is supposed to be displayed at, usually the max width that the container can reach, take care beyond 1000 and do not exceed 2000. */
  displayWidth?: number;
  /** img loading for browser nativ lazy loading */
  loading?: "lazy" | "eager";
  className?: string;
  fetchPriority?: "high" | undefined;
}> = ({
  src: srcProp,
  alt,
  srcWidth,
  srcHeight,
  displayWidth,
  loading,
  className,
  fetchPriority,
}) => {
  const srcSets = displayWidth
    ? getCaasSrcs(srcProp, srcWidth, displayWidth)
    : { src: srcProp };

  return (
    <img
      className={cx(styles.base, className)}
      style={{
        aspectRatio: srcWidth / srcHeight,
      }}
      {...srcSets}
      alt={alt}
      loading={loading}
      /* eslint-disable react/no-unknown-property */
      fetchpriority={fetchPriority}
    />
  );
};

export default ResponsiveImage;
