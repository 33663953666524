import React from "react";

import styles from "./ResponsiveIframe.module.css";
import { convertToEmbed } from "./utils";

const ResponsiveIframe: React.FC<{
  /** the url of the iframe, can automatically convert common video urls into embed urls, will also guess aspect ratio based off this unless srcWidth and srcHeight is given */
  src: string;
  /** the width of the content referenced in src, used to generate a the aspect ratio  */
  srcWidth?: number;
  /** the height of the content referenced in src, used to generate a the aspect ratio  */
  srcHeight?: number;
  /** iframe title attribute */
  title?: string;
  /** loading for browser nativ lazy loading */
  loading?: "lazy" | "eager";
}> = ({ title, src }) => {
  const aspectRatio = src.includes("youtube.com/shorts")
    ? 9 / 16
    : src.includes("youtu.be") || src.includes("youtube") /* ||
      src.includes("brightcove") */
      ? 16 / 9
      : 4 / 6;
  return (
    <iframe
      className={styles.base}
      title={title}
      src={convertToEmbed(src)}
      style={{
        aspectRatio,
      }}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
    />
  );
};

export default ResponsiveIframe;
