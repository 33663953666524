import { sanitizeUrl } from "@braintree/sanitize-url";
import { getWindowHref } from "@src/app/utils/helpers";
import { Helmet } from "react-helmet-async";

import {
  ARTICLE_PUBLISHER,
  FORMAT_DETECTION,
  OG_SITE_NAME,
  TWITTER_CARD,
  URL_ORIGIN,
  URL_ORIGIN_ALTERNATE,
} from "./Constants";

export type MetaTagsProps = {
  title: string;
  description: string;
  slug: string;
  shortLink?: string;
  ogType?: string;
  articlePageclass?: string;
  image?: string;
  publishedTime?: string;
  keywords?: string[];
  author?: string;
  section?: string;
  isArticlePage?: boolean;
  ogTitle?: string;
  ogDescription?: string;
  canonical?: string;
  ogImage?: string;
};

export default function MetaTags({
  title,
  description,
  slug,
  shortLink,
  ogType = "article",
  articlePageclass = "article",
  image,
  publishedTime,
  keywords = [],
  author,
  section,
  isArticlePage = false,
  ogTitle,
  ogDescription,
  canonical,
  ogImage,
}: MetaTagsProps) {
  const alternateUrl: string = URL_ORIGIN_ALTERNATE + slug;
  const canonicalUrl: string =
    canonical && canonical != ""
      ? canonical
      : `${URL_ORIGIN}${slug === "home" ? "" : slug}`;
  const shortLinkUrl: string = URL_ORIGIN + shortLink;
  // Sanitize the URL before using it in the tag
  const sanitizedUrl = canonicalUrl
    ? sanitizeUrl(canonicalUrl)
    : sanitizeUrl(getWindowHref());

  const metaTitle =
    ogTitle && ogTitle != ""
      ? ogTitle
      : title && title != ""
        ? title
        : OG_SITE_NAME;
  const metaDesc =
    ogDescription && ogDescription != "" ? ogDescription : description;

  // Facebook minumum dimension: 600 (w) x 315 (h) pixels (1.91:1)
  // LinkedIn minumum dimension: 1200 (w) x 627 (h) pixels (1.91:1)
  // Twitter minumum dimension: 300 (w) x 157 (h) pixels (2:1)
  // Optimal image dimensions for Facebook, LinkedIn & Linkin to ensure high-quality display on high-resolution screens.
  const metaImgMinWidth = 1200;
  const metaImgMinHeight = 627;
  const metaImg =
    ogImage && ogImage != ""
      ? ogImage +
        (isArticlePage ? `?w=${metaImgMinWidth}&h=${metaImgMinHeight}` : "")
      : image && image != ""
        ? image +
          (isArticlePage ? `?w=${metaImgMinWidth}&h=${metaImgMinHeight}` : "")
        : "";

  return (
    <Helmet>
      <title>{metaTitle}</title>
      {/* Search Engine tags */}
      <meta name="robots" content="index, follow" />
      <link rel="alternate" href={alternateUrl} />
      <link rel="canonical" href={sanitizedUrl} />
      {shortLink && <link rel="shortlink" href={shortLinkUrl} />}
      {metaDesc && <meta name="description" content={metaDesc} />}
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}
      <meta name="article:pageclass" content={articlePageclass} />
      <meta name="format-detection" content={FORMAT_DETECTION} />

      {/* google-site-verification */}
      <meta
        name="google-site-verification"
        content="yE8InBB5xS9wFHBukGOmijn1jY0-jRkvCsmEcCjwBuc"
      />
      {/* Open Graph tags */}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={sanitizedUrl} />
      <meta property="og:title" content={metaTitle} />

      {metaDesc && <meta property="og:description" content={metaDesc} />}

      {metaImg && <meta property="og:image" content={metaImg} />}
      {metaImg && <meta property="og:image:url" content={metaImg} />}
      {metaImg && <meta property="og:image:secure" content={metaImg} />}

      <meta property="og:locale" content="en_SG" />

      {/* Article tags */}
      {isArticlePage && (
        <meta name="robots" content="max-image-preview:large" />
      )}
      {isArticlePage && <meta property="article:author" content={author} />}
      {isArticlePage && <meta property="article:section" content={section} />}
      {isArticlePage &&
        keywords.map((keyword, index) => (
          <meta key={index} property="article:tag" content={keyword} />
        ))}
      <meta property="article:publisher" content={ARTICLE_PUBLISHER} />
      <meta property="article:published_time" content={publishedTime} />

      {/* Twitter tags */}
      <meta property="twitter:card" content={TWITTER_CARD} />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:url" content={sanitizedUrl} />
      {metaDesc && <meta property="twitter:description" content={metaDesc} />}
      {metaImg && <meta property="twitter:image" content={metaImg} />}
    </Helmet>
  );
}
