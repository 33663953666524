import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import NotFound from "@src/app/components/Blocks/NotFound/NotFound";
import Pagination from "@src/app/components/Blocks/Pagination/Pagination";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import BreadCrumb from "@src/app/components/Elements/BreadCrumb/BreadCrumb";
import GAData from "@src/app/components/Elements/GAData/GAData";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import StandardCardLayout from "@src/app/components/Layouts/StandardCardLayout/StandardCardLayout";
import ContributorProfile from "@src/app/components/Modules/ContributorPage/ContributorProfile/ContributorProfile";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import useContributorApi from "@src/app/hooks/useContributorApi";
import useContributorArticlesApi from "@src/app/hooks/useContributorArticlesApi";
import useContributorContext from "@src/app/hooks/useContributorContext";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { ArticleCard } from "@src/app/types/Article";
import { logEnd, logStart } from "@src/app/utils/helpers";
import { useLocation, useSearchParams } from "react-router-dom";

import styles from "./Contributor.module.css";

export default function ContributorPage() {
  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting();

  const tResponse = useContributorContext();
  const statusCode = tResponse.statusCode;
  const payload = tResponse.payload;

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 0;

  const timeStartMain = logStart();
  const { data: apiPayloadContributor, isLoading } = useContributorApi({
    pathname: pathname,
  });
  logEnd("Contributor useContributorApi", timeStartMain);

  const timeStartArticleApi = logStart();
  const { data: apiPayloadArticles } = useContributorArticlesApi({
    pathname,
    page,
  });
  logEnd("Contributor useContributorArticlesApi", timeStartArticleApi);

  if (statusCode != 200 || !payload || typeof payload === "string") {
    return <NotFound />;
  }

  const pathnameValue = pathname.split("/").filter(Boolean);
  const level2 = pathnameValue[0];

  const contributor =
    apiPayloadContributor?.data.results || payload.contributor || undefined;
  const contributorName = contributor?.name as string;
  const totalArticles =
    apiPayloadArticles?.data.totalResults ||
    payload.contributorArticles?.totalResults ||
    0;
  const articles =
    apiPayloadArticles?.data.results ||
    payload.contributorArticles?.results ||
    [];
  const totalPages = Math.ceil(totalArticles / 9);

  const breadcrumbItems = [
    { label: "Home", link: "/" },
    { label: contributorName },
  ];

  return (
    <>
      <AdSettings />

      <GAData
        title={contributorName}
        level2={level2}
        contenttype="index/listing page"
        pagination={page}
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial("authors")}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <BreadCrumb items={breadcrumbItems} />
        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.LB1,
            slot: GoogleAdsSlotFactory.lb1("authors"),
          }}
        />
      </MainContainer>

      {contributor && (
        <ContributorProfile {...contributor} pathname={pathname} />
      )}

      <MainContainer>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <StandardCardLayout>
              {articles.length > 0 &&
                articles.map((article: ArticleCard) => {
                  const {
                    id,
                    articleName,
                    articlePath,
                    authors,
                    category,
                    publishedDate,
                    imgUrl,
                    imgWidth,
                    imgHeight,
                    blurb,
                  } = article;
                  return (
                    <ArticleCardContent
                      key={id}
                      articleTitle={articleName}
                      articlePath={articlePath}
                      authors={authors}
                      categoryLabelProps={{
                        href: category.path,
                        label: category.name,
                      }}
                      date={formatPublishedDate(publishedDate)}
                      hasAuthorAndDate
                      hasBackground
                      hasSidePadding
                      hasSnippet
                      hasTitleMargin
                      imgUrl={imgUrl}
                      imgWidth={imgWidth}
                      imgHeight={imgHeight}
                      paragraphContent={blurb}
                    ></ArticleCardContent>
                  );
                })}
            </StandardCardLayout>

            <div className={styles.pagination}>
              <Pagination isUseQueryParams totalPages={totalPages} />
            </div>
          </>
        )}
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish("authors")}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
