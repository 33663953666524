// useLatestArticlesApi.ts
import { LatestArticlesResData } from "@src/api/v1/latestArticles";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

export default function useLatestArticlesApi(): SWRResponse<LatestArticlesResData> {
  const swrRes: SWRResponse<LatestArticlesResData> =
    useSWR<LatestArticlesResData>(
      getApiFunctionUrl("latestArticles"), // Directly pass the result as an argument
      fetcher,
    );
  return swrRes;
}
