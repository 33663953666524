const dfpPreview = `
function querystring(key) {
  var re=new RegExp('(?:\\\\?|&)'+key+'=(.*?)(?=&|$)','gi');
  var r=[], m;
  while ((m=re.exec(document.location.search)) != null) r[r.length]=m[1];
  return r;
}
var dfp_preview_ids = querystring("dfp_preview");
if(!dfp_preview_ids === '' || typeof dfp_preview_ids === undefined ){
  googletag.pubads().setTargeting('dfp_preview', dfp_preview_ids);
}
`;

export default dfpPreview;
