import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validateAndSanitizeTerm from "@src/app/helper/validateAndSanitizeTerm";
import cx from "classnames";
import type { ChangeEvent, FormEvent, MouseEvent, ReactElement } from "react";
import { useState } from "react";

import styles from "./Search.module.css";

export type Props = Readonly<{
  testId?: string;
  className?: string;
  isDark?: boolean;
  hasText?: boolean;
}>;

export default function Search({
  testId = "search",
  className,
  isDark,
  hasText,
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [fadeOutAnimation, setIsFadeOutAnimtion] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  function onOpenModal(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  }

  function onModalClose(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsFadeOutAnimtion(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadeOutAnimtion(false);
      setSearchTerm("");
    }, 250);
  }

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setSearchTerm(e.target.value);
  }

  function onSearchButtonClick(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    // format keyword that contain space
    const sanitizedSearchTerm = validateAndSanitizeTerm(searchTerm);
    window.open(`/search?keyword=${sanitizedSearchTerm}`, "_self");
    setTimeout(() => {
      setIsOpen(false);
      setIsFadeOutAnimtion(false);
      setSearchTerm("");
    }, 250);
  }

  return (
    <>
      <button
        onClick={onOpenModal}
        data-testid={`${testId}-searchIcon`}
        aria-label="Search"
        className={cx(
          styles.searchIconContainer,
          className,
          isDark && styles.dark,
        )}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        {hasText && (
          <span
            className={styles.searchText}
            data-testid={`${testId}-searchWithText`}
          >
            Search
          </span>
        )}
      </button>
      {isOpen && (
        <div
          data-testid={`${testId}-searchModal`}
          className={cx(styles.searchModal, fadeOutAnimation && styles.out)}
        >
          <form
            onSubmit={onSearchButtonClick}
            data-testid={`${testId}-searchActionContainer`}
            className={cx(styles.searchActionContainer)}
          >
            <input
              placeholder="Enter keywords to start your search"
              data-testid={`${testId}-searchInput`}
              type="text"
              onChange={onInputChange}
              className={cx(styles.searchInput)}
            />
            <button
              type="submit"
              data-testid={`${testId}-searchButton`}
              aria-label="Submit Search"
              className={cx(
                styles.searchButton,
                searchTerm.trim() === "" && styles.disabled,
              )}
              disabled={searchTerm.trim() === ""}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </form>

          <button
            onClick={onModalClose}
            data-testid={`${testId}-searchModalCloseBtn`}
            className={cx(styles.searchModalCloseBtn)}
            aria-label="Close Search Modal"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
    </>
  );
}
