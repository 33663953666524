import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./Column.module.css";

export type Props = Readonly<{
  testId?: string;
  className?: string;
  children: ReactNode;
}>;

export default function Column({
  children,
  className,
  testId = "column",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.column, className)}>
      {children}
    </div>
  );
}
