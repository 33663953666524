import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import getStoryMediaCaption from "@src/app/helper/getStoryMediaCaption";
import getStoryMediaElement from "@src/app/helper/getStoryMediaElement";
import { ArticleCard, Author } from "src/app/types/Article";
import {
  CUEElement,
  CUERelatedContributorProfile,
  CUESections,
  CUETags,
  OSSearchHits,
  PartialCueArticle,
} from "src/app/types/Opensearch";
import getArticleCardDataFromOsSearchHit from "src/app/utils/getArticleCardDataFromSearchHits";

import {
  ProcessedElements,
  processElements,
  ResolvedCUEElement,
} from "./elements";

interface BreadCrumbItem {
  label: string;
  link?: string;
}

interface OgFields {
  ogTitle: string;
  ogDescription: string;
}

interface SeoFields {
  metaTitle: string;
  metaDescription: string;
  metaRobots: string;
  yoastMeta?: string;
  canonicalUrl?: string;
}

export interface Article {
  id: string;
  heroImgUrl: string;
  heroImgCaption?: string | undefined;
  heroImgWidth?: number;
  heroImgHeight?: number;
  authors: Author[];
  translators: string;
  publishedDate: string;
  headline: string;
  standfirst: string;
  bodyElements: ResolvedCUEElement[];
  tags: CUETags[];
  category: string;
  breadCrumbs: BreadCrumbItem[];
  displayType: string;
  recommendedArticles?: ArticleCard[];
  seoFields?: SeoFields;
  ogFields?: OgFields;
  ogImageUrl?: string;
}

export type ArticlePageListing = {
  article?: Article;
  grapeshot?: string | null;
};

export function getArticleFromOSResponse(
  searchHits: OSSearchHits<PartialCueArticle>[],
): Article {
  const article: OSSearchHits<PartialCueArticle> = searchHits[0];
  const context = article._source?.data?.context;
  const tags = context?.tags as CUETags[];
  const sections = context?.sections as CUESections[];
  const relatedCUEContributors =
    context?.relatedContributorProfile as CUERelatedContributorProfile[];
  const elements = context?.elements ?? ([] as CUEElement[]);
  const authors: Author[] =
    relatedCUEContributors.length > 0
      ? relatedCUEContributors.map(
          (rawAuthor: CUERelatedContributorProfile) => ({
            url: rawAuthor.content?.urlPath || "/",
            name: rawAuthor.content?.fields?.name || "",
            designation: rawAuthor.content?.fields?.designation || "",
            headshotImageUrl:
              (
                rawAuthor.content?.headshotImage?.[0]?.content.fields[
                  "square_320-caas"
                ] ??
                rawAuthor.content?.headshotImage?.[0]?.content.fields[
                  "square_320"
                ]
              )?.url || "",
          }),
        )
      : [];
  const translators = context?.byline?.translator ?? "";
  const id = context?.id ?? "";
  const media = context?.media ?? [];
  const publishedDate = context?.updated ?? "";
  const relatedMedia = media[0]?.content?.fields;
  const storyMedia = getStoryMediaElement(elements);
  const storyMediaCaption = getStoryMediaCaption(elements);

  // Assign values to heroImgUrl and heroImgCaption
  const heroImgStoryMedia =
    storyMedia[0]?.["original-caas"] ?? storyMedia[0]?.["original"];
  const heroImgRelatedMedia =
    relatedMedia?.["original-caas"] ?? relatedMedia?.["original"];

  const heroImgUrl = heroImgStoryMedia?.url || heroImgRelatedMedia?.url || "";
  const heroImgCaption = storyMediaCaption[0] || relatedMedia?.caption;

  const heroImgWidth =
    heroImgStoryMedia?.width || heroImgRelatedMedia?.width || 900;
  const heroImgHeight =
    heroImgStoryMedia?.height || heroImgRelatedMedia?.height || 600;

  const processedElements = processElements(elements);
  const { headline, standfirst, bodyElements } =
    processedElements as ProcessedElements;
  const categoryName: string = sections[0].name || "";
  const category: string = sections[0].uniqueName || "";

  const breadCrumbs: BreadCrumbItem[] = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: categoryName,
      link: `/${sections[0].directoryName}`,
    },
    {
      label: headline,
    },
  ];
  const displayType = context?.displaySetting?.displayType ?? "";
  const seoFields: SeoFields | undefined = context?.seoFields;
  const ogFields: OgFields | undefined = context?.ogFields;
  const ogImage = context?.ogImages ?? [];
  const ogImageUrl = ogImage[0]?.content?.fields?.["original"]?.url;

  return {
    id,
    headline,
    standfirst,
    heroImgUrl,
    heroImgCaption,
    heroImgWidth,
    heroImgHeight,
    authors,
    translators,
    publishedDate,
    bodyElements,
    tags,
    category,
    breadCrumbs,
    displayType,
    seoFields,
    ogFields,
    ogImageUrl,
  };
}

export function getRecommendedArticlesFromOSResponse(
  oSSearchHits: OSSearchHits<PartialCueArticle>[],
): ArticleCard[] {
  const recommendedArticles: ArticleCard[] = oSSearchHits.map(
    getArticleCardDataFromOsSearchHit,
  );

  return recommendedArticles;
}

export default function useArticle(): TRouteWithoutRedirect<
  ArticlePageListing | null,
  string
> {
  const customContext: CustomContext<
    TRouteWithoutRedirect<ArticlePageListing | null, string>
  > = useRouteContext();

  const tResponse: TRouteWithoutRedirect<ArticlePageListing | null, string> =
    customContext.context;

  return tResponse;
}
