import { Helmet } from "react-helmet-async";

import adsOverlayCss from "./adsOverlayCss.head.css?raw";

// a self executing function injected into the head
const overlaySEF = function () {
  console.log("overlaySEF");
  console.time("overlaySEF");
  const htmlClassList = document.documentElement.classList;
  const isDfpTest = window.location.search.includes("dfp_preview");
  // window.dfp_preview_ids != "" &&
  // window.dfp_preview_ids != undefined &&
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  // window.dfp_preview_ids?.length != 0;

  if (
    screen.width > 767 &&
    (!document.cookie.includes("topoverlayDisplayed=yes") || isDfpTest)
  ) {
    console.log("hasoverlay: AdSettings if - add");
    htmlClassList.add("hasoverlay");
    setTimeout(function () {
      if (!htmlClassList.contains("hasprestitial")) {
        htmlClassList.remove("hasoverlay");
      }
    }, 4e3);
  } else {
    console.log("hasoverlay: AdSettings else - no add");
    console.log("screen.width", screen.width, screen.width > 767);
    console.log("cookie", document.cookie.includes("topoverlayDisplayed=yes"));
    console.log("isDfpTest", isDfpTest);
  }
};

export default function AdSettings() {
  return (
    <Helmet>
      <>
        <script>{`(${overlaySEF.toString()})()`}</script>
        <style type="text/css">{adsOverlayCss}</style>
        <link
          href="https://adtag.sphdigital.com/tag/smx/sites/tc/smx_prebid.js"
          rel="preload"
          as="script"
        />
      </>
    </Helmet>
  );
}
