import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Column from "@src/app/components/Layouts/Column/Column";
import Row from "@src/app/components/Layouts/Row/Row";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "./Spinner.module.css";

type Props = Readonly<{
  children?: string;
  testId?: string;
}>;

export default function Spinner({
  children,
  testId = "spinner",
}: Props): ReactElement {
  return (
    <div className={styles.spinner} data-testid={testId}>
      <Row>
        <Column>
          <div className={cx(styles.wrapper, styles.center)}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faSpinner} />
            </div>
            <span className={styles.description}>{children}</span>
          </div>
        </Column>
      </Row>
    </div>
  );
}
