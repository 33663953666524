import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import SocialShareMobile from "@src/app/components/Blocks/ShareButtons/SocialShareMobile/SocialShareMobile";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import ArticleBlurb from "@src/app/components/Elements/Typography/ArticleBlurb/ArticleBlurb";
import { ResolvedCUEElement } from "@src/app/hooks/useArticle/elements";
import {
  getArticleBody,
  getArticleBodyComic,
  getArticleTags,
  getRecommendedArticles,
} from "@src/app/pages/Article/helpers";
import { ArticleCard } from "@src/app/types/Article";
import { CUETags } from "@src/app/types/Opensearch";
import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";

import ArticleImage from "../ArticleImage/ArticleImage";
import NewsletterWidget from "../NewsletterWidget/NewsletterWidget";
import styles from "./ArticleContent.module.css";

export type Props = Readonly<{
  nlIsDark?: boolean;
  nlIsThinkCulture?: boolean;
  nlHeaderTitle?: string;
  nlBodyText?: string;
  standfirst?: string;
  heroImgUrl?: string;
  heroImgCaption?: string | undefined;
  heroImgWidth?: number;
  heroImgHeight?: number;
  bodyElements: ResolvedCUEElement[];
  // category?: string;
  tags: CUETags[];
  recommendedArticles?: ArticleCard[];
  recommendedArticlesIsLoading?: boolean;
  isArticleAComic?: boolean;
  isOutbrainEnabled?: boolean;
  pathname: string;
  testId?: string;
}>;

export default function ContentRight({
  nlIsDark,
  nlIsThinkCulture,
  nlHeaderTitle = "",
  nlBodyText = "",
  standfirst = "",
  heroImgUrl = "",
  heroImgCaption,
  heroImgWidth,
  heroImgHeight,
  bodyElements,
  //category,
  tags,
  recommendedArticles,
  recommendedArticlesIsLoading,
  isArticleAComic,
  isOutbrainEnabled,
  pathname,
  testId = "content-right",
}: Props): ReactElement {
  const articleTags = getArticleTags(tags);
  const articleRecommendedArticles = getRecommendedArticles(
    recommendedArticles as ArticleCard[],
  );
  const articleBody = isArticleAComic
    ? getArticleBodyComic(bodyElements)
    : getArticleBody(bodyElements);

  // Create a ref for the specific div
  const recomendedArticleView = useRef<HTMLDivElement | null>(null);
  const sendDataToGTM = useGTMDispatch();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust threshold as needed (0.5 means at least 50% of the div should be visible)
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !isLoaded &&
          !recommendedArticlesIsLoading
        ) {
          // Call sendDataToGTM method when the div becomes visible
          sendDataToGTM({
            event: "tcCustomEvent",
            eventCategory: "recommended article",
            eventLabel: "recommended article",
            eventAction: "view",
          });
          setIsLoaded(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const currentRef = recomendedArticleView.current; // Copy the ref value to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [recommendedArticlesIsLoading, isLoaded, sendDataToGTM]);

  return (
    <>
      <div data-testid={testId}>
        {/* Article Blurb / Standfirst */}
        <ArticleBlurb>{standfirst}</ArticleBlurb>

        {/* share buttons for mobile */}
        <SocialShareMobile />
        {/* Article Image */}
        {!isArticleAComic && heroImgUrl != "" && (
          <ArticleImage
            src={heroImgUrl}
            alt={heroImgCaption}
            caption={heroImgCaption}
            width={heroImgWidth}
            height={heroImgHeight}
          />
        )}

        {/* Article Body */}
        {articleBody}

        {/* Article Tags */}
        <div className={styles.tags}>{articleTags}</div>

        {/* Recommended Articles */}
        {recommendedArticlesIsLoading ? (
          <Spinner />
        ) : (
          <div
            className={styles.recommendedArticles}
            ref={recomendedArticleView}
          >
            {articleRecommendedArticles}
          </div>
        )}

        {/* Newsletter Widget */}
        <div className={styles.newsletterMobile}>
          <NewsletterWidget
            isDark={nlIsDark}
            isThinkCulture={nlIsThinkCulture}
            headerTitle={nlHeaderTitle}
            bodyText={nlBodyText}
          />
        </div>

        {/* Outbrain */}
        {isOutbrainEnabled && (
          <div
            className="OUTBRAIN"
            data-src={`https://www.thinkchina.sg${pathname}`}
            data-widget-id="AR_1"
          ></div>
        )}
      </div>
    </>
  );
}
