import type { ReactElement, ReactNode } from "react";

import styles from "./Subheader.module.css";

type Props = Readonly<{
  children: ReactNode;
  testId?: string;
}>;

export default function Subheader({ children, testId }: Props): ReactElement {
  return (
    <h4 data-testid={testId} className={styles.subheader}>
      {children}
    </h4>
  );
}
