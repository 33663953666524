import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { Author } from "@src/app/types/Article";

export interface ContributorListing {
  totalResults?: number;
  results?: Author[];
}

export default function useContributorListingContext(): TRouteWithoutRedirect<
  ContributorListing | null,
  string
> {
  const customContext: CustomContext<
    TRouteWithoutRedirect<ContributorListing | null, string>
  > = useRouteContext();

  const tResponse: TRouteWithoutRedirect<ContributorListing | null, string> =
    customContext.context;
  return tResponse;
}
