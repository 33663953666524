export type LinkProps = Readonly<{
  title: string;
  url: string;
}>;

export const TOPICS_LIST: LinkProps[] = [
  {
    title: "politics",
    url: "/politics",
  },
  {
    title: "economy",
    url: "/economy",
  },
  {
    title: "technology",
    url: "/technology",
  },
  {
    title: "history",
    url: "/history",
  },
  {
    title: "society",
    url: "/society",
  },
  {
    title: "culture",
    url: "/culture",
  },
  {
    title: "cartoon",
    url: "/cartoon",
  },
  {
    title: "caixin",
    url: "/tags/caixin",
  },
];

export const MISC_LIST: LinkProps[] = [
  {
    title: "about us",
    url: "/about-us",
  },
  {
    title: "contributors",
    url: "/contributors",
  },
  {
    title: "newsletter",
    url: "/newsletter",
  },
];
