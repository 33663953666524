import { StoryQueueType } from "@src/api/v1/storyQueue";
import { GetStoryQueueArticlesRes } from "@src/api/v1/storyQueue";
import useSWR, { SWRResponse } from "swr";

import getApiFunctionUrl from "../../helper/getApiFunctionUrl";
import fetcher from "../../utils/fetcher";

type StoryQueueQueryParams = {
  type: StoryQueueType;
};

export default function useStoryQueue({
  type,
}: StoryQueueQueryParams): SWRResponse<GetStoryQueueArticlesRes> {
  const queryString = `?type=${type}`;
  const swrRes: SWRResponse<GetStoryQueueArticlesRes> =
    useSWR<GetStoryQueueArticlesRes>(
      getApiFunctionUrl(`storyQueue${queryString}`),
      fetcher,
    );
  return swrRes;
}
