export enum AdvertisementTypeEnum {
  LB1 = "lb1",
  IMU1 = "imu1",
  IMU2 = "imu2",
  MIDARTICLESPECIAL = "midarticlespecial",
  PRESTITIAL = "prestitial",
  CATFISH = "catfish",
}

export type AdUnitProps = {
  rootClassName?: string;
  type: AdvertisementTypeEnum;
  slot: string;
  id?: string;
};

export type AdTargetingType = {
  key: string;
  value: string | string[];
};
