import { TopArticleResData } from "@src/api/v1/popularArticles";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

type PopularArticlesQueryParams = {
  size: number;
  date: string;
};

export default function usePopularArticles({
  size,
  date,
}: PopularArticlesQueryParams): SWRResponse<TopArticleResData> {
  const queryString: string = `?size=${size}&date=${date}`;
  const swrRes: SWRResponse<TopArticleResData> = useSWR<TopArticleResData>(
    getApiFunctionUrl(`popularArticles${queryString}`),
    fetcher,
  );
  return swrRes;
}
