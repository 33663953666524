import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./PopularThisMonthLayout.module.css";

export type Props = Readonly<{
  title?: string;
  testId?: string;
  children: ReactNode;
}>;

export default function PopularThisMonthLayout({
  children,
  testId,
}: Props): ReactElement {
  return (
    <div
      data-testid={`${testId}`}
      className={cx(styles.popularThisMonthLayoutWrapper)}
    >
      <div className={cx(styles.cards)}>{children}</div>
    </div>
  );
}
