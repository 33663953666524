import { NeuronProvider as Provider, SPAConfig } from "@sphtech/neuron";
import { ReactElement, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import TrackerProvider from "./TrackerProvider";

type Props = {
  children: ReactNode;
};

export default function NeuronProvider({ children }: Props): ReactElement {
  const neuronConfig: SPAConfig = {
    trackedSPAEvents: [
      {
        eventType: "click",
        selector: "button, a",
      },
      {
        eventType: "elementVisible",
        selector: "div[id^='sph_cdp_'], div[id^='dfp-ad-']",
      },
    ],
    sessionApiUrl: import.meta.env.VITE_NEURON_API_URL as string,
    sessionApiToken: import.meta.env.VITE_NEURON_API_TOKEN as string,
  };

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isPreview = urlParams.has("preview_token");

  if (isPreview) {
    return <>{children}</>;
  } else {
    return (
      <Provider config={neuronConfig}>
        <TrackerProvider>{children}</TrackerProvider>
      </Provider>
    );
  }
}
