import ZaoBaoLogo from "@src/app/assets/logo-zb@2x.svg";
import type { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function PoweredBy(): ReactElement {
  return (
    <div className={styles.poweredByContent}>
      <a
        href="https://www.zaobao.com.sg/"
        target="_blank"
        rel="noreferrer"
        aria-label="Visit Lianhe Zaobao's official website (opens in a new tab)"
      >
        <img
          loading="lazy"
          src={ZaoBaoLogo}
          alt="ZB logo"
          width="132"
          height="41"
        />
      </a>
    </div>
  );
}
