import Avatar from "@src/app/components/Elements/Avatar/Avatar";
import Column from "@src/app/components/Layouts/Column/Column";
import Row from "@src/app/components/Layouts/Row/Row";
import type { ReactElement } from "react";

import styles from "./AuthorCard.module.css";

type Props = Readonly<{
  name: string;
  url: string;
  designation: string;
  imageUrl: string;
  testId?: string;
}>;

export default function AuthorCard({
  name,
  url,
  designation,
  imageUrl,
  testId = "author-card",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.authorCardContainer}>
      <Row isVCenter={true}>
        <Column className={styles.left}>
          <span>By </span>
          <a
            data-testid={`${testId}-name`}
            href={url}
            className={styles.link}
            aria-label={`Author Name: ${name}`}
          >
            {name}
          </a>
          <div data-testid={`${testId}-designation`}>{designation}</div>
        </Column>
        <Column className={styles.right}>
          <div data-testid={`${testId}-image`} className={styles.image}>
            <Avatar src={imageUrl} alt={name}></Avatar>
          </div>
        </Column>
      </Row>
    </div>
  );
}
