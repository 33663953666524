export function convertToEmbed(url?: string) {
  if (!url || url.includes("/embed/")) {
    return url;
  }
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (e) {
    // nada
  }
  const hostname = parsedUrl?.hostname.toLowerCase() || "";
  let embedUrl = url;
  if (
    parsedUrl &&
    ["youtube.com", "www.youtube.com", "m.youtube.com", "youtu.be"].includes(
      hostname,
    )
  ) {
    console.log("youtube");
    const id =
      parsedUrl.searchParams.get("v") ||
      parsedUrl.pathname
        .split("/")
        .filter(
          (segment) => segment && !["shorts", "watch"].includes(segment),
        )[0];
    const time = parsedUrl.searchParams.get("t");
    embedUrl = `https://www.youtube.com/embed/${id}${
      time ? `?start=${time}` : ""
    }`;
  }
  // else if (url.includes("twitter.com")) {
  // } else if (
  //   url.includes("facebook.com") &&
  //   !url.includes("facebook.com/plugins/post.php")
  // ) {
  //   //oembed for facebook
  //   const parts = url.split("/");
  //   const postCode = parts[parts.length - 1];
  //   embedUrl = `https://www.facebook.com/plugins/post.php?href=https://www.facebook.com/harpersbazaarsingapore/posts/${postCode}`;
  // }

  return embedUrl;
}
