import ResponsiveImage from "@src/app/components/Elements/ResponsiveImage/ResponsiveImage";
import type { ReactElement } from "react";

import styles from "./Avatar.module.css";

type Props = Readonly<{
  src: string;
  alt: string;
}>;

export default function Avatar({ alt, src }: Props): ReactElement {
  return (
    <div className={styles.avatar}>
      <ResponsiveImage
        className={styles.avatar}
        src={src}
        alt={alt}
        srcWidth={190}
        srcHeight={190}
        loading="lazy"
        displayWidth={190}
      />
    </div>
  );
}
