import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./BreadCrumb.module.css";

type BreadCrumbItem = {
  label: string;
  link?: string;
};

type Props = Readonly<{
  items: BreadCrumbItem[];
}>;

export default function BreadCrumb({ items }: Props): ReactElement {
  return (
    <div className={styles.breadcrumbs}>
      <div className={cx(styles.textLight, styles.breadcrumbBlock)}>
        <div className={styles.breadcrumbStyle}>
          <div className={styles.breadcrumbContainer}>
            <div className={styles.breadcrumbContentInner}>
              <nav className={styles.breadcrumb} role="navigation">
                <h2 id="system-breadcrumb" className={styles.visuallyHidden}>
                  Breadcrumb
                </h2>
                <ol>
                  {items.map((item, id) => (
                    <li key={id}>
                      {item.link !== undefined ? (
                        <>
                          <a
                            href={item.link}
                            aria-label={`Navigate to ${item.label}`}
                          >
                            {item.label}
                          </a>
                          <span> - </span>
                        </>
                      ) : (
                        <span className={styles.onlyText}> {item.label} </span>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
