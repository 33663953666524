import imageSrc from "@src/app/assets/images/icon-notfound.svg";
import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import { ReactElement } from "react";

import styles from "./NotFound.module.css";

export default function NotFound(): ReactElement {
  return (
    <MainContainer>
      <div className={styles.notFound}>
        <Row>
          <Column className={styles.colIcon}>
            <img alt="" className="img-responsive" src={imageSrc} />
          </Column>

          <Column className={styles.colText}>
            <h1>Error 404!</h1>

            <h3>Sorry, Page Not Found.</h3>

            <p>We can’t found the page that you’re looking for.</p>
            <InternalLink to="/">Back to homepage</InternalLink>
          </Column>
        </Row>
      </div>
    </MainContainer>
  );
}
