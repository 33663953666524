/*
 * Helper function to retrieve query parameters from storybook, in order to enable testing of components which triggers URL parameters changes
 * @returns {QueryStringMap}
 */
interface QueryStringMap {
  [key: string]: string | undefined;
}

export function getQueryString(): QueryStringMap {
  const queryStringKeyValue: string[] = window.parent.location.search
    .replace("?", "")
    .split("&");
  const queryStringMap: QueryStringMap = {};
  if (queryStringKeyValue.length) {
    for (let i = 0; i < queryStringKeyValue.length; i++) {
      const key: string = "$" + queryStringKeyValue[i].split("=")[0];
      const value: string = queryStringKeyValue[i].split("=")[1];
      queryStringMap[key] = value;
    }
  }
  return queryStringMap;
}

/*
 * Helper function to retrieve the URL origin of the parent. THis is created in order to enable testing of components which triggers URL parameters changes
 * @returns string
 */
export function getParentURL(): string {
  const baseUrl = document.location.origin;
  const path = getQueryString().$path;

  return `${baseUrl}/?path=${path}`;
}

/*
 * Helper function to retrieve the URL of the pagination when rendered in storybook. THis is created in order to enable testing of components which triggers URL parameters changes
 * @returns string
 */
export function getStoryBookPaginationURL(pageLink: string): string {
  // return getParentURL().includes("page=") ? pageLink: `${getParentURL()}${pageLink.replace('?', '&')}`;
  return `${getParentURL()}${pageLink.replace("?", "&")}`;
}
