import { Helmet } from "react-helmet-async";
import newRelicAgentV_1_256 from "./scripts/nr-loader-spa.1.256.1.min.js?url";

const id = import.meta.env.VITE_NEW_RELIC_ID as string | undefined;
const key = import.meta.env.VITE_NEW_RELIC_KEY as string | undefined;

const isConfigured = !!(id && key);
if (!isConfigured) {
  console.warn("Skipping NewRelic import.meta.env", {
    VITE_NEW_RELIC_ID: id,
    VITE_NEW_RELIC_KEY: key,
  });
}

const NewRelic = isConfigured
  ? () => {
      return (
        <Helmet>
          <script>
            {`
              ;window.NREUM||(NREUM={});NREUM.init={session_replay:{enabled:true,block_selector:'',mask_text_selector:'*',sampling_rate:10.0,error_sampling_rate:100.0,mask_all_inputs:true,collect_fonts:true,inline_images:false,inline_stylesheet:true,mask_input_options:{}},distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}};
              ;NREUM.loader_config={accountID:"4409272",trustKey:"225023",agentID:"${id}",licenseKey:"${key}",applicationID:"${id}"};
              ;NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"${key}",applicationID:"${id}",sa:1};
            `}
          </script>
          <script type="text/javascript" src={newRelicAgentV_1_256} async />
        </Helmet>
      );
    }
  : () => null;

export default NewRelic;
