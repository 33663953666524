import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import StandardCardLayout from "@src/app/components/Layouts/StandardCardLayout/StandardCardLayout";
import NewsletterWidget from "@src/app/components/Modules/LandingPage/NewsletterWidget/NewsletterWidget";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import { ArticleCard } from "@src/app/types/Article";

import styles from "./PopularContentSectionTwo.module.css";

export type ArticleCardBlock = {
  articles: ArticleCard[];
  testId?: string;
};

export default function PopularContentSectionTwo({
  articles,
  testId,
}: ArticleCardBlock) {
  return (
    <>
      <div className={styles.popularContentSectionTwo} data-testid={testId}>
        <MainContainer>
          <NewsletterWidget
            bodyText="Insights on China, right in your mailbox. Sign up now."
            headerTitle="Get the ThinkChina weekly newsletter"
          />
        </MainContainer>
      </div>
      <div className={styles.subArtcles}>
        <MainContainer>
          <Row>
            <Column>
              <StandardCardLayout>
                {articles
                  .filter((_, index: number) => index < 2)
                  .map((article: ArticleCard) => {
                    const {
                      articleName,
                      id,
                      blurb,
                      category,
                      authors,
                      imgUrl,
                      imgWidth,
                      imgHeight,
                      publishedDate,
                    } = article;

                    // Adding refcode for CTR tracking for latest-articles
                    const articlePath = `${article.articlePath}?ref=home-latest-articles`;

                    return (
                      <ArticleCardContent
                        key={id}
                        articlePath={articlePath}
                        articleTitle={articleName}
                        authors={authors}
                        categoryLabelProps={{
                          href: category.path,
                          label: category.name,
                        }}
                        date={formatPublishedDate(publishedDate)}
                        hasAuthorAndDate
                        hasSidePadding
                        hasBackground
                        hasSnippet
                        imgUrl={imgUrl}
                        imgWidth={imgWidth}
                        imgHeight={imgHeight}
                        paragraphContent={blurb}
                        testId="article-card-content-popular"
                      />
                    );
                  })}
                {articles
                  .filter((_, index: number) => index >= 2)
                  .map((article: ArticleCard) => {
                    const {
                      articleName,
                      id,
                      blurb,
                      category,
                      authors,
                      imgUrl,
                      imgWidth,
                      imgHeight,
                      publishedDate,
                    } = article;

                    // Adding refcode for CTR tracking for latest-articles
                    const articlePath = `${article.articlePath}?ref=home-latest-articles`;

                    return (
                      <ArticleCardContent
                        key={id}
                        articlePath={articlePath}
                        articleTitle={articleName}
                        authors={authors}
                        categoryLabelProps={{
                          href: category.path,
                          label: category.name,
                        }}
                        date={formatPublishedDate(publishedDate)}
                        hasAuthorAndDate
                        hasSidePadding
                        hasBackground
                        hasSnippet
                        imgUrl={imgUrl}
                        imgWidth={imgWidth}
                        imgHeight={imgHeight}
                        paragraphContent={blurb}
                        testId="article-card-content-popular"
                      />
                    );
                  })}
              </StandardCardLayout>
            </Column>
          </Row>
        </MainContainer>
      </div>
    </>
  );
}
