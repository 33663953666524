import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWeixin } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";

import styles from "./SocialShareMobile.module.css";

interface WindowWithA2A extends Window {
  a2a?: {
    init: (type: string) => void;
  };
}

export default function SocialShareMobile(): ReactElement {
  const [isSharing, setIsSharing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsSharing(!isSharing);
  };

  // Define addtoany classes
  const a2aClasses = "a2a_kit a2a_kit_size_32";
  const sendDataToGTM = useGTMDispatch();
  const handleClickGTM = (eventLabel: string) => {
    sendDataToGTM({
      event: "tcCustomEvent",
      eventCategory: "social sharing",
      eventLabel: eventLabel,
      eventAction: "click",
    });
  };

  useEffect(() => {
    const windowWithA2A = window as WindowWithA2A;

    if (windowWithA2A.a2a) {
      windowWithA2A.a2a.init("page");
    }

    windowWithA2A.addEventListener("scroll", () => {
      if (windowWithA2A.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, [isSharing]);

  return (
    <>
      {isVisible && (
        <div className={cx(styles.socialDropdown, styles.isScroll)}>
          <button
            type="button"
            className={styles.socialToggle}
            onClick={handleToggle}
            aria-label={isSharing ? "Close Social Share" : "Open Social Share"}
          >
            <FontAwesomeIcon icon={faShare} style={{ marginRight: "5px" }} />
          </button>
          {isSharing && (
            <div className={styles.dropdownMenu}>
              <span className={a2aClasses}>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className="a2a_button_facebook"
                  aria-label="Share on Facebook"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    color="#c2c2c2"
                    onClick={() => {
                      handleClickGTM("facebook");
                    }}
                  />
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a className="a2a_button_twitter" aria-label="Share on Twitter">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    color="#c2c2c2"
                    onClick={() => {
                      handleClickGTM("twitter");
                    }}
                  />
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a className="a2a_button_wechat" aria-label="Share on WeChat">
                  <FontAwesomeIcon
                    icon={faWeixin}
                    color="#c2c2c2"
                    onClick={() => {
                      handleClickGTM("wechat");
                    }}
                  />
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className="a2a_button_whatsapp"
                  aria-label="Share on WhatsApp"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    color="#c2c2c2"
                    onClick={() => {
                      handleClickGTM("whatsapp");
                    }}
                  />
                </a>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
