import { ContributorArticlesResData } from "@src/api/v1/contributorArticles";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

type SearchQueryParams = {
  pathname: string;
  page: number;
};

export default function useContributorArticlesApi({
  pathname,
  page,
}: SearchQueryParams): SWRResponse<ContributorArticlesResData> {
  const queryString = `?pathname=${pathname}&page=${page}`;
  const swrRes: SWRResponse<ContributorArticlesResData> =
    useSWR<ContributorArticlesResData>(
      getApiFunctionUrl(`contributorArticles${queryString}`),
      fetcher,
    );
  return swrRes;
}
