import type { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function FooterLinks(): ReactElement {
  return (
    <div className={styles.footerLinks}>
      <div className={styles.linksInline}>
        <a
          href="mailto:thinkchina@sph.com.sg?ref=footer-page"
          aria-label="Contact Us via Email"
        >
          Contact Us
        </a>
        <a
          href="https://www.sph.com.sg/legal/website_tnc/?ref=footer-page"
          aria-label="View Terms & Conditions"
        >
          Terms &amp; Conditions
        </a>
        <a
          href="https://www.sph.com.sg/legal/pdpa/?ref=footer-page"
          aria-label="View Data Protection Policy"
        >
          Data Protection Policy
        </a>
      </div>
    </div>
  );
}
