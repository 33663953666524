import type { ReactElement } from "react";

import styles from "./Quotes.module.css";

type Props = Readonly<{
  children: string;
  testId?: string;
}>;

export default function Quotes({ children, testId }: Props): ReactElement {
  return (
    <blockquote data-testid={testId} className={styles.quotes}>
      <p>{children}</p>
    </blockquote>
  );
}
