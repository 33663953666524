import { TagListingResData } from "@src/api/v1/tags";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

type TagQueryParams = {
  tagName: string;
  page: number;
};

export default function useTagListingApi({
  tagName,
  page,
}: TagQueryParams): SWRResponse<TagListingResData> {
  const queryString = `/${tagName}?page=${page}`;
  const swrRes: SWRResponse<TagListingResData> = useSWR<TagListingResData>(
    getApiFunctionUrl(`tags${queryString}`),
    fetcher,
  );
  return swrRes;
}
