import { RecommendedArticlesResData } from "@src/api/v1/recommendedArticles";
import getApiFunctionUrl from "@src/app/helper/getApiFunctionUrl";
import fetcher from "@src/app/utils/fetcher";
import useSWR, { SWRResponse } from "swr";

type SearchQueryParams = {
  topic: string;
  pathname: string;
};

export default function useRecommendedArticlesApi({
  topic,
  pathname,
}: SearchQueryParams): SWRResponse<RecommendedArticlesResData> {
  const queryString = `/${topic}?pathname=${pathname}`;
  const swrRes: SWRResponse<RecommendedArticlesResData> =
    useSWR<RecommendedArticlesResData>(
      getApiFunctionUrl(`recommendedArticles${queryString}`),
      fetcher,
    );
  return swrRes;
}
