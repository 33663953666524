import type { ReactElement, ReactNode } from "react";

import styles from "./HighlightContainer.module.css";

type Props = Readonly<{
  children: ReactNode;
  testId?: string;
}>;

export default function HighlightContainer({
  children,
  testId,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.highlight}>
      {children}
    </div>
  );
}
