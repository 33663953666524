import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./HeroLayout.module.css";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode[];
}>;

export default function HeroLayout({
  children,
  testId = "hero-layout",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.heroLayoutWrapper)}>
      <MainContainer>
        <Row>
          {children.length > 1 ? (
            <>
              <Column className={cx(styles.col8Tablet, styles.bannerLeft)}>
                {children[0]}
              </Column>
              <Column className={cx(styles.col4Tablet, styles.bannerRight)}>
                <Row>
                  <Column>{children[1]}</Column>
                </Row>
                <Row>
                  <Column>{children[2]}</Column>
                </Row>
              </Column>
            </>
          ) : (
            <Column className={styles.banner}>{children[0]}</Column>
          )}
        </Row>
      </MainContainer>
    </div>
  );
}
