import {
  ResolvedCUEElement,
  ResolvedExternalLinkElement,
  ResolvedInternalLinkElement,
} from "@src/app/hooks/useArticle/elements";
import { FC } from "react";
import { Link } from "react-router-dom";

// TODO: These should be generated from CUE typings if possible
// For a full list of annotations, see:
// https://github.com/SPHTech/poccue/blob/develop/service/engine/definition/shared/story-element-type/paragraph.xml

export type CUEParagraphAnnotationComponents =
  | Record<
      CUEParagraphAnnotationType,
      CUEParagraphAnnotationComponent | keyof HTMLElementTagNameMap
    >
  | undefined;

type DataAnnotation = {
  "data-annotationname"?: string;
  "data-annotationvalue"?: ResolvedCUEElement | string;
};
type AnnotationComponentProps = React.PropsWithChildren<{
  element?: (ResolvedCUEElement | string) & DataAnnotation;
}>;

export type CUEParagraphAnnotationComponent = FC<AnnotationComponentProps>;

export type CUEParagraphProps = {
  text: string;
  annotationComponents?: CUEParagraphAnnotationComponents;
};

export enum CUEParagraphAnnotationType {
  Bold = "bold",
  InternalLink = "internal_link",
  Italic = "italic",
  SubheadItalic = "subhead_italic",
  Strike = "strike",
  Subscript = "subscript",
  Underline = "underline",
  Superscript = "superscript",
  Capitals = "capitals",
  MarkOut = "mark-out",
  Note = "note",
  BoldItalic = "bolditalic",
  DropCap = "dropcap",
  Dateline = "dateline",
  LeadIn = "leadin",
  WireCredit = "wire_credit_c",
  Title = "title",
  Subtitle = "subtitle",
  TagonByline = "tagon_byline",
  ExternalLink = "external_link",
  Debug = "debug",
}

export function DefaultDebug(props: AnnotationComponentProps) {
  const { ...rest } = props;

  return (
    <span
      data-testid="missing-annotation"
      className="debug"
      style={{
        outline: import.meta.env.DEV ? "solid 2px magenta" : "none",
        outlineStyle: "dotted",
      }}
      {...rest}
    />
  );
}

// TODO: Handle custom fields
// defined in // https://github.com/SPHTech/poccue/blob/develop/service/engine/definition/shared/story-element-type/paragraph.xml

export const defaultAnnotationComponents: CUEParagraphAnnotationComponents = {
  [CUEParagraphAnnotationType.InternalLink]: function InternalLink(
    props: AnnotationComponentProps,
  ) {
    if (!props.element) return <DefaultDebug {...props} />;

    const fields = props.element as ResolvedInternalLinkElement;

    return (
      <Link
        to={fields.relation?.urlPath ?? ""}
        target={fields.fields?.newWindow?.value ? "_blank" : "_self"}
        rel={fields.fields?.noFollow?.value ? "nofollow noopener" : "noopener"}
      >
        {props.children}
      </Link>
    );
  },
  [CUEParagraphAnnotationType.ExternalLink]: function ExternalLink(
    props: AnnotationComponentProps,
  ) {
    if (!props.element) return <DefaultDebug {...props} />;

    const fields = props.element as ResolvedExternalLinkElement;

    return (
      // By default:
      // 1. We add rel="noopener" to prevent the new page from being able to access the window.opener object
      // 2. Browsers already add noopener behaviour when opening target=_blank links
      // 3. We want referrer information, so do NOT add rel=noreferrer
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={fields.fields?.uri.value ?? "#"}
        target={fields.fields?.newWindow.value ? "_blank" : "_self"}
        rel={fields.fields?.noFollow.value ? "nofollow noopener" : "noopener"}
      >
        {props.children}
      </a>
    );
  },
  [CUEParagraphAnnotationType.Bold]: "b",
  [CUEParagraphAnnotationType.Italic]: "i",
  [CUEParagraphAnnotationType.SubheadItalic]: "i",
  [CUEParagraphAnnotationType.Strike]: "s",
  [CUEParagraphAnnotationType.Subscript]: "sub",
  [CUEParagraphAnnotationType.Superscript]: "sup",
  [CUEParagraphAnnotationType.Capitals]: function DefaultUppercase(
    props: AnnotationComponentProps,
  ) {
    return <span className="uppercase">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.Underline]: "u",
  [CUEParagraphAnnotationType.MarkOut]: "mark",
  [CUEParagraphAnnotationType.BoldItalic]: function DefaultBoldItalic(
    props: AnnotationComponentProps,
  ) {
    return (
      <b>
        <i>{props.children}</i>
      </b>
    );
  },
  [CUEParagraphAnnotationType.DropCap]: function DefaultDropCap(
    props: AnnotationComponentProps,
  ) {
    return <span className="dropcap">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.Dateline]: function DefaultDateline(
    props: AnnotationComponentProps,
  ) {
    return <span className="dateline">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.LeadIn]: function DefaultLeadIn(
    props: AnnotationComponentProps,
  ) {
    return <span className="leadin">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.WireCredit]: function DefaultWireCredit(
    props: AnnotationComponentProps,
  ) {
    return <span className="wire-credit">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.Title]: function DefaultTitle(
    props: AnnotationComponentProps,
  ) {
    return <span className="title">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.Subtitle]: function DefaultSubtitle(
    props: AnnotationComponentProps,
  ) {
    return <span className="subtitle">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.TagonByline]: function DefaultTagonByline(
    props: AnnotationComponentProps,
  ) {
    return <span className="tagon-byline">{props.children}</span>;
  },
  [CUEParagraphAnnotationType.Note]: DefaultDebug,
  [CUEParagraphAnnotationType.Debug]: DefaultDebug,
};
