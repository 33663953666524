import Paragraph from "@src/app/components/Elements/Typography/Paragraph/Paragraph";
import formatRawHtml from "@src/app/helper/formatRawHtml";
import { ReactElement } from "react";

import styles from "./SearchResult.module.css";

export interface SearchResultProps {
  searchResults: {
    articleName: string;
    articlePath: string;
    id: string;
    blurb: string;
  }[];
  totalResult: number;
}

export default function SearchResult({
  searchResults,
  totalResult,
}: SearchResultProps): ReactElement {
  // const highlightMatch = (sentence: string) => {
  //   const regex = new RegExp(`(${searchKeywords})`, "gi");
  //   return sentence.split(regex).map((chunk, index) => {
  //     return regex.test(chunk) ? <strong key={index}>{chunk}</strong> : chunk;
  //   });
  // };

  const truncateDescription = (text: string, maxLength: number): string => {
    if (typeof text !== "string") {
      return ""; // Return an empty string or handle it as needed
    }

    if (text.length <= maxLength) {
      return text;
    }

    // Trim the text to the maximum length
    const truncatedText = text.substring(0, maxLength);

    // Find the last space in the truncated text to ensure a proper word cut-off
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    // If a space is found, truncate the text at the last space
    if (lastSpaceIndex !== -1) {
      return truncatedText.substring(0, lastSpaceIndex) + "...";
    }

    // If no space is found, simply add ellipsis
    return truncatedText + "...";
  };

  return (
    <div className={styles.searchResults}>
      <h2 className={styles.title}>
        {totalResult > 0 ? "Search results" : "Your search yielded no results."}
      </h2>
      {searchResults.length > 0 ? (
        <ol>
          {searchResults.map((result) => (
            <li key={result.id}>
              <h3>
                {" "}
                <a
                  href={result.articlePath}
                  aria-label={`Go to ${result.articleName}`}
                >
                  {result.articleName}
                </a>
              </h3>
              <Paragraph>
                <p>{formatRawHtml(truncateDescription(result.blurb, 500))}</p>
              </Paragraph>
            </li>
          ))}
        </ol>
      ) : null}
    </div>
  );
}
