import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleCard } from "src/app/types/Article";

export interface ContributorPageItems {
  contributor: Contributor | undefined;
  contributorArticles: ContributorArticles | undefined;
}
export interface Contributor {
  name: string;
  url: string;
  imageUrl: string;
  designation: string;
  bio: string;
}

export interface ContributorArticles {
  totalResults?: number;
  results?: ArticleCard[];
}

export default function useContributorContext(): TRouteWithoutRedirect<
  ContributorPageItems | null,
  string
> {
  const customContext: CustomContext<
    TRouteWithoutRedirect<ContributorPageItems | null, string>
  > = useRouteContext();
  const tResponse: TRouteWithoutRedirect<ContributorPageItems | null, string> =
    customContext.context;
  return tResponse;
}
